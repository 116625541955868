import ItemModule from "../Atoms/ItemModule";
import { Zoom } from "react-reveal";
export default function Menu({ setApp, menus,currentMenu }) {
    return (
        <div className="row w100 hcenter mtop25">
            <Zoom cascade>
                <div className="mxw800px row vcenter hspace mtop25 menTam">
                    {menus.map((menu)=>{return(<ItemModule currentMenu={currentMenu} setApp={setApp} menu={menu} svg={menu.svg} text={menu.menu} />)})}
                </div>
            </Zoom>
        </div>
    );
}
