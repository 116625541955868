import { Services } from "../../Services";
import { useEffect, useState } from "react";
import useFiltroDatos from "../../../../../Hooks/useFiltroDatos";
import { ProgressBar } from 'primereact/progressbar';
import SearchData from "../../../../Organism/Search";
import { ThreeCircles } from "react-loader-spinner";
import ExpertiseDetails from "../Dialogs/ExpertiseDetails";
import Reload from "../../../../Atoms/Reload";
export default function Expertise() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showDetalles, setShowDetalles] = useState(false)
  const [expertise, setExpertise] = useState(null)
  const { datosFiltrados, handleChangeFiltro, setDatosIniciales } =
    useFiltroDatos(data);

  useEffect(() => {
    setDatosIniciales(data);
  }, [data]);

  const getData = () => {
    setLoader(true);
    Services.getDataExpertise((response) => {
      response.then((data) => {
        console.log(data);
        setData(data);
        setLoader(false);
      });
    });
  }
  useEffect(getData, []);

  const renderLoader = () => (
    <div className="row w100 hcenter pad15 vcenter">
      <ThreeCircles
        height="43"
        width="43"
        color="#0061FF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
    </div>
  );

  const renderTableHeader = () => (
    <thead className="table_header encab2">
      <tr className="table_header white bold hspace vcenter fontSizeSm">
        <th className="column">Expertise</th>
        <th className="column">Actividades</th>
        <th className="column">Responsables</th>
        <th className="column">Inicio</th>
        <th className="column">Fin</th>
        <th className="column">Pregreso</th>
        <th className="column">Acciones</th>
      </tr>
    </thead>
  );
  const renderTableBody = (datosFiltrados) => (
    <tbody className="tbodyTablaSeleccion">
      {datosFiltrados.map(renderTableRow)}
    </tbody>
  );

  const renderColumn = (key, content) => (
    <td className="column" key={key}>
      {content}
    </td>
  );

  const renderPregress = (key, content) => (
    <td className="column" key={key}>
      <ProgressBar
        aria-label="Status"
        style={{ color: "#2B2B2B" }}
        value={content}>
      </ProgressBar>
    </td>
  );

  const renderAction = (key, content) => (
    <td className="column hcenter vcenter text-centrado" key={key}>
      <div
        onClick={() => {
          setExpertise(content)
          setShowDetalles(true)
        }}
        style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
      >
        <i className="pi pi-eye" style={{ fontSize: "2rem" }}></i>
      </div>
    </td>
  );

  const renderTableRow = (row, index) => (
    <tr className="row_table pointier" key={index} onClick={() => { }}>
      {renderColumn("name", row.name)}
      {renderColumn("n_actividades", row.n_actividades)}
      {renderColumn("n_responsables", row.n_responsables)}
      {renderColumn("f_inicio", row.f_inicio)}
      {renderColumn("f_fin", row.f_fin)}
      {renderPregress("progress", row.progress)}
      {renderAction("actions", row)}
    </tr>
  );

  const renderTableContainer = (datosFiltrados) => (
    <div className="mxw1000px row vcenter hspace menTam">
      <div className="table-container">
        <table className="w100" cellPadding={0} cellSpacing={0}>
          {renderTableHeader()}
          {renderTableBody(datosFiltrados)}
        </table>
      </div>
    </div>
  );
  return (
    <div className="w100 row hcenter">
      <div className="mxw1000px row hcenter wrap">
        <div className="row w100 mtop15">
          <SearchData handleChangeFiltro={handleChangeFiltro} />
          <Reload onClick={getData} />
        </div>
        {loader ? renderLoader() : renderTableContainer(datosFiltrados)}
      </div>
      <ExpertiseDetails show={showDetalles} expertise={expertise} onHide={() => { setShowDetalles(false) }} />
    </div>
  );
}
