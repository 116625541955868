import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
import { axios_instance, baseURL, Post } from "../../App";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import logoE from "./../../static/images/logoE.png";
import { Calendar } from "primereact/calendar";

function pass(){}
function Input({ field, data, setData, setModel, modelo, uuid,entidad,setRows }) {
  pass();
  const [value, setValue] = useState(field.value); // Es el valor del field
  const [checked, setChecked] = useState(false);
  var dato = {};
  var datArray = [];
  if (field.type == "select")
    if (!field.multiple)
      dato = { name: field.value.label, code: field.value.value };
      //Mauricio
    else {
      var dato = [];
      for (let i in field.value) {
        dato.push({
          name: field.value[i].label,
          code: field.value[i].value,
        });
      }
    }
  var datos = [];
  for (let i in field.values) {
    datos.push({ name: field.values[i].label, code: field.values[i].value });
  }
  const [select, setSelect] = useState(dato);
  const [select2, setSelect2] = useState(datos);

  useEffect(() => {
    pass(select);
  }, [select]);

  useEffect(() => {
    pass(select2);
  }, [select2]);

  useEffect(() => {
    if (field.type != "select") {
      var cpmodel = { ...modelo };
      cpmodel[field.name] = value;
      pass(value);
      setModel(cpmodel);
      pass(cpmodel);
    } else {
      var cpmodel = { ...modelo };
      pass(value);
      if (value.value) cpmodel[field.name] = value.value;
      else cpmodel[field.name] = value;
      setModel(cpmodel);
      pass(cpmodel);
    }
  }, [value]);

  useEffect(() => {}, [checked]);

  if (field.type == "text")
    return (
      <span className="p-float-label" key={field.name}>
        {/* <InputText
          id={field.name}
          value={modelo[field.name]}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          style={{ width: "100%", height: 40 }}
        /> */}
        <div className="row w100 hcenter mtop25">
          <div className="mxw1000px row vcenter">
              <h3 className="fontSizeMd ">{field.print_name}</h3>
          </div>
        </div>
        <div className="row w100 hcenter ">
          <div className="mxw1000px row vcenter">
            <p className="mxw800px">{modelo[field.name]}</p>
          </div>
        </div>
      </span>
    );

  if (field.type == "checkbox")
    return (
      <span
        className="p-float-label"
        key={field.name}
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
        <label htmlFor={field.name}>{field.print_name}</label>
      </span>
    );

  if (field.type == "number")
    return (
      <span className="p-float-label" key={field.name}>
        <InputText
          id={field.name}
          value={modelo[field.name]}
          style={{ width: "100%", height: 40 }}
          onChange={(e) => {
            if (!isNaN(e.target.value)) setValue(e.target.value);
          }}
        />
        <label htmlFor={field.name}>{field.print_name}</label>
      </span>
    );

  if (field.type == "email")
    return (
      <span className="p-float-label" key={field.name}>
        <InputText
          id={field.name}
          value={modelo[field.name]}
          style={{ width: "100%", height: 40 }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <label htmlFor={field.name}>{field.print_name}</label>
      </span>
    );

  if (field.type == "file")
    return (
      <span className="p-float-label" key={field.name}>
        <FileUpload
          mode="basic"
          name={field.name}
          style={{ width: "100%", height: 40 }}
          accept={field.accept}
       
          chooseLabel={`Subir ${field.name}`}
          onUpload={(e) => {
            pass(e);
          }}
          onSelect={(e) => {
            var data = new FormData();
            data.append("contrato", e.files[0]);
            data.append("uuid", uuid);
            data.append(
              "public_key",
              "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
            );
            data.append(
              "private_key",
              "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
            );

            Post(`${entidad.path}/${field.name}`,(response)=>{pass(response);setRows(response.data.data)},(error)=>{pass(error);},false,"",data,true)
           
          }}
        />
      </span>
    );

  if (field.type == "select") {
    if (!field.multiple) {
      var dato = { name: field.value.label, code: field.value.value };
      var datos = [];
      for (let i in field.values) {
        datos.push({
          name: field.values[i].label,
          code: field.values[i].value,
        });
      }

      if (datos.length == 0) {
        datos.push(dato);
      }
      if (!field.multiple)
        return (
          <span className="p-float-label" key={field.name}>
            <Dropdown
              optionLabel="name"
              placeholder={field.print_name}
              className="w-full md:w-14rem"
              value={select}
              style={{ width: "100%", height: 40 }}
              options={datos}
              onChange={(e) => {
                setValue(e.value.code);
                setSelect(e.value);
              }}
            />
            <label htmlFor={field.name}>{field.print_name}</label>
          </span>
        );
    } else {
      var dato = [];
      for (let i in field.value) {
        dato.push({
          name: field.value[i].label,
          code: field.value[i].value,
        });
      }

      var datos = [];
      for (let i in field.values) {
        datos.push({
          name: field.values[i].label,
          code: field.values[i].value,
        });
      }

      var valueOK = [];
      for (let i in modelo[field.name]) {
       
        for (let j in datos) {
          
          if ((modelo[field.name][i].value == datos[j].code)||(modelo[field.name][i] == datos[j].code))
            valueOK.push({
              name: datos[j].name,
              code: datos[j].code,
            });
        }
      }

   
      return (
        <span className="p-float-label" key={field.name}>
          <MultiSelect
            value={valueOK}
            onChange={(e) => {
              setSelect2(e.value);
              var datos = [];
              for (let i in e.value) {
                datos.push(e.value[i].code);
              }
              var modelocp = { ...modelo };
              modelocp[field.name] = datos;
              setModel(modelocp);
            }}
            options={datos}
            optionLabel="name"
            display="chip"
            style={{ width: "100%", height: 40 }}
            placeholder={`Selecciona ${field.print_name}`}
            className=""
            maxSelectedLabels={3}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );
    }
  }

  if (field.type == "date")
    return (
      <span className="p-float-label" key={field.name}>
        <Calendar
          value={new Date(modelo[field.name])}
          style={{ width: "100%", height: 40 }}
          onChange={(e) => {
            pass(modelo[field.name]);
            setValue(e.value);
          }}
          dateFormat="yy-mm-dd"
        />
        <label htmlFor={field.name}>{field.print_name}</label>
      </span>
    );
}

function AddRow({ field, editO, dato, setDatos, datos, modelo, modelO }) {
  pass(dato);
  pass(modelO);
  const [edit, setEdit] = useState(false);
  const [model, setModel] = useState(dato ? dato : modelO);

  useEffect(() => {}, [edit]);

  useEffect(() => {
    pass(model);
  }, [model]);

  if (editO) {
    if (model)
      return (
        <tr className="row_table">
          {field.fields.map((fieldO) => {
            return (
              <td className="column">
                <div className="row vcenter">
                  {fieldO == field.fields[0] && (
                    <Icon
                      icon={"material-symbols:add-circle"}
                      className="fontSizeMd  pointier c_color"
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        if (model[fieldO.name].length > 0) {
                          setDatos([...datos, model]);
                          var modelcp = {};

                          for (let i in field.fields) {
                            modelcp[field.fields[i].name] = "";
                          }
                          setModel(modelcp);
                        }
                      }}
                    />
                  )}

                  <input
                    className={`input_actividad w100 ${
                      fieldO == field.fields[0] ? "required" : ""
                    }`}
                    required
                    placeholder={fieldO.print_name}
                    id={"id"}
                    value={model[fieldO.name]}
                    type={fieldO.type}
                    onChange={(e) => {
                      pass(model);
                      var modelcp = { ...model };
                      modelcp[fieldO.name] = e.target.value;
                      setModel(modelcp);
                      pass(modelcp);
                    }}
                  />
                </div>
              </td>
            );
          })}
          <td className="column"></td>
          <td className="column"></td>
        </tr>
      );
  }
  if (edit) {
    return (
      <tr className="row_table">
        {field.fields.map((fieldO) => {
          return (
            <td className="column">
              <input
                className="input_actividad w100"
                required
                placeholder={fieldO.print_name}
                id={"id"}
                value={model[fieldO.name]}
                type={fieldO.type}
                onChange={(e) => {
                  var modelcp = { ...model };
                  modelcp[fieldO.name] = e.target.value;
                  setModel(modelcp);
                  pass(modelcp);
                }}
              />
            </td>
          );
        })}
        <td className="column">
          <div className="row vcenter hcenter">
            <Icon
              icon={"material-symbols:edit-square-outline"}
              className="fontSizeMd hover_green pointier"
              onClick={() => {
                setEdit(false);
                let obj = model;
                let actividadesCP = [...modelo[field.id]];
                pass(actividadesCP);
                pass(obj);
                for (let i in actividadesCP) {
                  pass(actividadesCP[i]);
                  pass(dato);
                  if (dato == actividadesCP[i]) {
                    actividadesCP[i] = obj;
                    setDatos(actividadesCP);
                    pass(actividadesCP);
                    break;
                  }
                }
              }}
            />
          </div>
        </td>
        <td className="column">
          <div className="row vcenter hcenter">
            <Icon
              icon={"bi:trash"}
              className="fontSizeMd hover_green pointier"
              onClick={() => {
                let cpArray = modelo[field.id].filter(
                  (actividadO) => actividadO != dato
                );
                setDatos(cpArray);
              }}
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className="row_table">
      {Object.values(dato).map((value) => (
        <td className="column">
          <div className="row vcenter">{value}</div>
        </td>
      ))}

      <td className="column">
        <div className="row vcenter hcenter">
          <Icon
            icon={"material-symbols:edit-square-outline"}
            className="fontSizeMd hover_green pointier"
            onClick={() => {
              setModel(dato);
              setEdit(true);
            }}
          />
        </div>
      </td>
      <td className="column">
        <div className="row vcenter hcenter">
          <Icon
            icon={"bi:trash"}
            className="fontSizeMd hover_green pointier"
            onClick={() => {
              let cpArray = modelo[field.id].filter(
                (actividadO) => actividadO != dato
              );
              setDatos(cpArray);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

function ContentM({
  data, //response.data
  setData,
  model, //response.data.form_data (clave:valor)
  setModel, 
  select, //Cada seccion de los botones de la izquierda
  setSelect,
  entidad, //cada boton de los submenus
  setRows, // modificar los datos que se pintan
  uuid, // el uuid del registro
  setToggleModal,
}) {
  const [loading, setLoading] = useState(false);
  const [datos, setDatos] = useState([]);
  pass(model);

  const load = () => { //funcion para actualizar un registro
    setLoading(true);
    pass(data);
    pass(model);
    model["public_key"] =
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L";
    model["private_key"] =
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu";
    model["uuid"] = uuid;
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(model),
    };

    fetch(`${baseURL}/${entidad.path}/update2/`, requestOptions)
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then(({ status, json, ok }) => {
        setLoading(false);
        var message = "";
        if (!ok) message = json.detail.message;

        if (ok) {
          toast.success(`${entidad.print} Modificado`, { theme: "dark" });
          setRows(json.data);
        } else toast.error(`${status} ${message}`, { theme: "dark" });
      });
  };

  useEffect(() => {}, [loading]);

  const RowA = () => {
    var modelcp = {};
    for (let i in select.fields) {
      modelcp[select.fields[i].name] = "";
      pass(modelcp);
    }

    return (
      <AddRow
        field={select}
        editO={true}
        setDatos={setDatos}
        datos={model[select.id]}
        modelo={model}
        setModelo={setModel}
        modelO={modelcp}
      />
    );
  };

  useEffect(() => {
    var cpmodel = { ...model };
    if (select) cpmodel[select.id] = datos;
    setModel(cpmodel);
  }, [datos]);

  if (select)
    return (
      <div className="row w100">
        <div className="nav_modal row w100 wrap">
          <div>
            <div className="row w100 hcenter mtop15">
              <img
                src={logoE}
                alt=""
                width={100}
                height={100}
                style={{ background: "#fff", borderRadius: 10, padding: 10 }}
              />
            </div>
            <div className="w100 mtop15 row wrap">
              {data.fields.map((field) => (
                <div
                  className={`row w100 btn_menu pointier ${
                    select == field ? "active" : ""
                  }`}
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    if (field.type && field.type != "many") {
                      var params = new FormData();
                      params.append(
                        "public_key",
                        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                      );
                      params.append(
                        "private_key",
                        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                      );

                      params.append("uuid", uuid);
                      Post(
                        `${entidad.path}/${field.method}`,
                        (response) => {
                          pass(response);
                          setRows(response.data.data);
                          setToggleModal(false);
                        },
                        (error) => {
                          pass(error);
                        },
                        true,
                        `Empleado ${
                          field.name == "Activar" ? "Activado" : "Desactivado"
                        }`,
                        params
                      );
                    } else setSelect(field);
                  }}
                >
                  {field.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container_modal w100 row pad20 wrap vcenter">
          <div
            className="row w100 wrap hcenter vcenter"
            style={{ maxHeight: 500, overflowY: "scroll" }}
          >
            {select.type == "many" ? (
              <table className="w100 " cellPadding={0} cellSpacing={0}>
                <tr className="bold table_header white">
                  {select.fields.map((label) => (
                    <td className="column ">{label.print_name}</td>
                  ))}
                  <td className="column ">
                    <div className="row hcenter">Editar</div>
                  </td>
                  <td className="column ">
                    <div className="row hcenter">Borrar</div>
                  </td>
                </tr>
                {model[select.id].map((dato) => {
                  return (
                    <AddRow
                      field={select}
                      editO={false}
                      dato={dato}
                      datos={datos}
                      setDatos={setDatos}
                      modelo={model}
                      setModelo={setModel}
                      modelO={dato}
                    />
                  );
                })}

                <RowA />
              </table>
            ) : (
              select.fields.map((field) => (
                <Input
                  field={field}
                  data={data}
                  setData={setData}
                  modelo={model}
                  setModel={setModel}
                  uuid={uuid}
                  entidad={entidad}
                  setRows={setRows}
                />
              ))
            )}
          </div>
          <div className="row w100 hright">
            <div className="card flex flex-wrap justify-content-center gap-3">
              <Button
                label={`Guardar`}
                icon="pi pi-check"
                loading={loading}
                onClick={() => {
                  load();
                }}
                style={{ height: 40, width: 120 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
}


export default function ModalEditDispositivosCH({ setToggleModal, uuid, entidad, setRows }) { //entidad: boton del submenu
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState(null); //response.data
  const [model, setModel] = useState(null); //response.data.form_data (clave:valor)
  const [select, setSelect] = useState(null); //Cada seccion de los botones de la izquierda

  useEffect(() => {
    if (data) setSelect(data.fields[0]);
  }, [data]);

  useEffect(() => {
    pass(select);
  }, [select]);

  useEffect(() => {
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    params.append("uuid", uuid);
    Post(
      `${entidad.path}/get_by_uuid/`,
      (response) => {
        pass(response);
        setModel(response.data.form_data);
        setData(response.data);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [uuid]);

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div className="close absolute pointier">
            <Icon
              icon={"ic:twotone-close"}
              onClick={() => setToggleModal(false)}
            />
          </div>
          <div
            className="row hcenter w100 pad20 vcenter"
            style={{ background: "#000", color: "#fff" }}
          >
            <h2 className="row vcenter">Editar</h2>
          </div>
        </div>
        {loader ? (
          <div
            className="row w100 hcenter pad15 vcenter mtop25 "
            style={{ height: 300 }}
          >
            <ThreeCircles
              height="43"
              width="43"
              color="#0061FF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <div className="row w100 wrap hcenter fontSizeSm">
            <ContentM
              data={data}
              setData={setData}
              model={model}
              setModel={setModel}
              select={select}
              setSelect={setSelect}
              entidad={entidad}
              setRows={setRows}
              uuid={uuid}
              setToggleModal={setToggleModal}
            />
          </div>
        )}
      </div>
    </>
  );
}
