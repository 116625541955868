
import { FileUpload } from 'primereact/fileupload';
import { ToastContainer, toast } from "react-toastify";
import { axios_instance, baseURL } from "../../App";
import { Post } from '../../App';

export default function FirmaUpload({uuid,setChangeF,changeF,setUpdateFirma,updateFirma}) {

    return (
        <div className="" style={{marginTop:20}}>
            <FileUpload mode="basic" accept="image/*" maxFileSize={3000000}
            onUpload={()=>{}}
            onSelect={(e)=>{
            var data = new FormData();
            setUpdateFirma(true)
            data.append("firma", e.files[0]);
            data.append("uuid", uuid);
            
            data.append(
              "public_key",
              "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
            );
            data.append(
              "private_key",
              "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
            );

            Post(`hoja_actividades/upload_firma/`,(response)=>{setUpdateFirma(false);},(error)=>{setUpdateFirma(false);},true,"Subiendo firma",data,true)
           
          }} chooseLabel="Seleccionar firma" />
        </div>  
    )
}
        