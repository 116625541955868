import { Dialog } from "primereact/dialog";
import ModalForm from "../../../../Organism/ModalForm";
import { useState } from "react";
import { Services } from "../Services";

export default function GrupoDetail({ show, grupo, onHide }) {
    const [dinamicValues, setDibamicValues] = useState({});
    const modal = {
        name: "Grupo de acceso",
        path: "Grupo de acceso",
        print: "Editar Grupo",
        add: true,
        isModal: true,
        component: null,
        formData: {
            body: {
                uuid: grupo?.uuid,
                name: grupo?.name,
                integrantes: grupo?.integrantes,
                files: grupo?.files.map((file)=>file.uuid)
            },
            endpoint: "",
            fields: [
                {
                    type: "textField",
                    name: "name",
                    text: "Nombre",
                    value: grupo?.name,
                    method: "",
                    getByEndPont: false,
                    onClick: () => { },
                },
                {
                    type: 'multiSelect',
                    name: "integrantes",
                    text: "Integrantes",
                    value: grupo?.integrantes,
                    method: "/EquiposSeleccion/getEmpleados/",
                    getByEndPont: true,
                    onClick: () => {},
                  },
                  {
                    type: 'multiSelect',
                    name: "files",
                    text: "Archivos",
                    value: grupo?.files.map((file)=>file.uuid),
                    method: "/proceso/LOV/",
                    getByEndPont: true,
                    onClick: () => {},
                  },
                
                {
                    type: "button",
                    name: "submit",
                    text: "Actualizar",
                    value: "",
                    method: "",
                    getByEndPont: false,
                    onClick: (body) => {
                        Services.put(body,()=>{
                            onHide()
                        },"/accessGroup/update")
                    },
                },
            ],
        },
        tipo: "tabla",
        seccion: "1",
    }
    return (
        <Dialog
            header={grupo?.name}
            visible={show}
            onHide={() => onHide()}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
            <ModalForm modal={modal} dinamicValues={dinamicValues} />

        </Dialog>
    )
}