import { useEffect,useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { Zoom } from "react-reveal";
import { Tag } from "primereact/tag";
export default function ListasEquipos({show, onHide, empleadoDetalle}) {
  const [lista, setLista] = useState([]);
  const [esquipoSelect, setEquipoSelect] = useState(true);
  useEffect(() => {
    if (esquipoSelect) setLista(empleadoDetalle?.equipos);
    else setLista(empleadoDetalle?.certificacionesActivas);
  }, [esquipoSelect]);
  useEffect(()=>{
    setEquipoSelect(true)
    setLista(empleadoDetalle?.equipos);
  },[empleadoDetalle])
  return (
    <Dialog
      header="Listas de equipo y certificaciones"
      visible={show}
      onHide={() => onHide()}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <div className="row wrap w100 hcenter">
        <div
          className="row w100 hcenter wrap  mtop15"
          style={{
            alignItems: "center",
            alignContent: "center",
            paddingInline: "5%",
          }}
        >
          <div className="card justify-content-center">
            <span className="p-buttonset">
              <Button
                label="Equipos"
                onClick={() => {
                  setEquipoSelect(true);
                }}
                className={`${
                  esquipoSelect ? "btn_lista_toggle_none" : "btn_lista_toggle"
                }`}
                icon="fa-solid fa-people-group"
                raised
              />
              <Button
                onClick={() => {
                  setEquipoSelect(false);
                }}
                className={`${
                  !esquipoSelect ? "btn_lista_toggle_none" : "btn_lista_toggle"
                }`}
                label="Certificaciones"
                icon="fa-solid fa-bars-staggered"
                raised
              />
            </span>
          </div>

          <div
            className="w100 wrap mtop15"
            style={{
              width: "100%",
              maxHeight: "500px",
              overflow: "auto",
            }}
          >
            {lista?.length > 0 ? (
              <div className="">
                <table className="w100 box_c" cellPadding={0} cellSpacing={0}>
                  <thead className="table_header encab2">
                    <tr className="table_header white bold hspace vcenter fontSizeSm">
                      <th className="column">No</th>
                      <th className="column">Clave</th>
                      <th className="column">Nombre</th>
                      {!esquipoSelect && <th className="column">Estatus</th>}
                    </tr>
                  </thead>
                  <Zoom cascade>
                    <tbody className="tbodyTablaSeleccion">
                      {lista.map((row, index) => (
                        <tr className="row_table" key={index}>
                          <td className="column text-centrado">{index}</td>
                          <td className="column text-centrado">{row.clave}</td>
                          <td className="column text-centrado">{row.nombre}</td>
                          {!esquipoSelect && (
                            <td
                              className="column hcenter vcenter text-centrado"
                              key={index}
                            >
                              <Tag
                                className="p-ml-2 tag_estatus_certificacion"
                                icon="fa-solid fa-thumbs-up"
                                severity="success"
                                value={row.estatus}
                              ></Tag>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Zoom>
                </table>
              </div>
            ) : (
              <div className="table_header pad15 row vcenter hcenter white bold fontSizeSm w200">
                No hay {esquipoSelect ? "equipos" : "certificaciones"} asignados
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
