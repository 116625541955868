import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Zoom } from "react-reveal";
import { baseURL } from "../../App";

export default function Slider({ menu_, setModule, module }) {
  const items = menu_.modules.map((_module) => (
    <Zoom>
      <div key={_module.name} className="row hcenter w100">
        <div
          className={`option pointier ${_module == module ? "active" : ""}`}
          onClick={() => setModule(_module)}
        >
          <div className="row h100 w100 hcenter vcenter wrap t_center">
            <div className="row w100 hcenter">
            <img
              src={`${baseURL}${_module.icon}`}
              alt="icon"
              className="white"
              style={{ width: 40 }}
            />
            </div>
            <span className="f_small">{_module.name}</span>
          </div>
        </div>
      </div>
    </Zoom>
  ));
  const thumbItems = (items, [setThumbIndex]) => {
    return items.map((item, i) => (
      <div key={item.name} className="thumb" onClick={() => setThumbIndex(i)}>
        {item}
      </div>
    ));
  };

  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbs] = useState(thumbItems(items, [setThumbIndex]));

  const responsive = {
    0: { items: 4 },
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
  };
  return (
    <div className="row hcenter w100 vcenter">
      <div className="row mxw600px vcenter">
        <AliceCarousel
          activeIndex={thumbIndex}
          autoWidth={false}
          infinite={items.length > 4 ? true : false}
          disableButtonsControls
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="default"
          onSlideChanged={syncThumbs}
        />
      </div>
    </div>
  );
}
