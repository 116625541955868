import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Zoom } from "react-reveal";
import ModalForm from "../../../../Organism/ModalForm";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { toUTCDate } from "../../../../Utils";
// Componente para cada fila en la tabla de plan de acción
const PlanAccionTableRow = ({ row, index,setData }) => {
  const opcionesDeFormato = { year: "numeric", month: "long", day: "numeric" };
  const formatoFecha = new Intl.DateTimeFormat("es-ES", opcionesDeFormato);
  const [modalesAbiertos, setModalesAbiertos] = useState([]);
  const [dinamicValues, setDibamicValues] = useState({});
  const modales = [
    {
      name: "Editar Plan",
      path: "editarPlan",
      icon: "fa-solid fa-pen-to-square btn-acciones",
      onClick: (opcion) => {
        setModalesAbiertos([...modalesAbiertos, opcion.name]);
      },
      print: "Editar Plan de Acción",
      add: false,
      formData: {
        body: {
          fechaLimite: row.fechaLimite,
          idCertificacion: row.uuid,
        },
        endpoint: "",
        fields: [
          {
            type: "dateField",
            name: "fechaLimite",
            text: "Fecha Fin",
            value: row.fechaLimite,
            method: "",
            getByEndPont: false,
            onClick: () => {},
          },
          {
            type: "button",
            name: "submit",
            text: "Guardar",
            value: "",
            method: "",
            getByEndPont: false,
            onClick: (body) => {
              Services.updateCertificacion(body, () => {
                toast.success("Plan editado");
                setModalesAbiertos(
                  modalesAbiertos.filter((modal_a) => modal_a !== "Editar Plan")
                );
                Services.getAllMiembros((response) => {
                  response.then((data) => {
                    setData(data);
                  });
                });
              });
            },
          },
        ],
      }
    },

  ];

  return (
    <tr className="row_table">
      <td className="column hcenter celdaConLinea hcenter">{index}</td>
      <td className="column hcenter celdaConLinea hcenter" key={index}>
        {row.nombre}
      </td>
      <td className="column hcenter celdaConLinea hcenter" key={index}>
        {formatoFecha.format(toUTCDate(row.fechaInicio))} al{" "}
        {formatoFecha.format(toUTCDate(row.fechaLimite))}
      </td>
      <td
        className="column hcenter celdaConLinea hcenter btn_De_acciones"
        key={index}
      >
        {modales.map((modal,index) => (
          <>
            <i
              className={modal.icon}
              onClick={() => {
                modal.onClick(modal);
              }}
              key={index}
            ></i>
            <Dialog
              key={modal.name}
              header={`${modal.add ? "Agregar " : ""}${modal.name}`}
              visible={modalesAbiertos.includes(modal.name)}
              onHide={() => {
                setModalesAbiertos(
                  modalesAbiertos.filter((modal_a) => modal_a !== modal.name)
                );
              }}
              style={{ width: "30vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
              <ModalForm modal={modal} dinamicValues={dinamicValues} />
            </Dialog>
          </>
        ))}
      </td>
    </tr>
  );
};

// Componente para la tabla de plan de acción
const PlanAccionTable = ({ empleadoDetalle,setData }) => {
  return (
    <table className="w100 box_c mtop25" cellPadding={0} cellSpacing={0}>
      <thead className="table_header encab2">
        <tr className="table_header white bold hspace vcenter fontSizeSm">
          <td className="column">No.</td>
          <td className="column">Nombre</td>
          <td className="column">Fecha de ejecucion</td>
          <td className="column"></td>
        </tr>
      </thead>
      <Zoom cascade>
        <tbody>
          {empleadoDetalle.planAccion.length > 0 ? (
            empleadoDetalle.planAccion.map((row, index) => (
              <PlanAccionTableRow row={row} index={index} setData={setData} />
            ))
          ) : (
            <tr className="row_table">
              <td className="column" colSpan={4}>
                <div className="row w100 hcenter">Sin plan de acción</div>
              </td>
            </tr>
          )}
        </tbody>
      </Zoom>
    </table>
  );
};

const PlanAccion = ({ empleadoDetalle, setData }) => {
  const [modalesAbiertos, setModalesAbiertos] = useState([]);
  const modales = [
    {
      name: "certificacion",
      path: "certificacion",
      onClick: (opcion) => {
        setModalesAbiertos([...modalesAbiertos, opcion.name]);
      },
      print: "Asignar Certificación",
      add: true,
      isModal: true,
      component: null,
      formData: {
        body: {
          fechaInicio: "",
          fechaLimite: "",
          certificacionesId: [],
        },
        endpoint: "",
        fields: [
          {
            type: "dateField",
            name: "fechaInicio",
            text: "Fecha Inicio",
            value: "",
            method: "",
            getByEndPont: false,
            onClick: () => {},
          },
          {
            type: "dateField",
            name: "fechaLimite",
            text: "Fecha Limite",
            value: "",
            method: "",
            getByEndPont: false,
            onClick: () => {},
          },
          {
            type: "multiSelect",
            name: "certificacionesId",
            text: "Certificaciones",
            value: [],
            method: `/CertificacionUsuario/cert_faltantes/${empleadoDetalle.uuid}`,
            getByEndPont: true,
            onClick: () => {},
          },
          {
            type: "button",
            name: "submit",
            text: "Crear",
            value: "",
            method: "",
            getByEndPont: false,
            onClick: (body) => {
              Services.addCertificacion(
                { ...body, idUser: empleadoDetalle.uuid },
                (response) => {
                  toast.success("Certificación agregada");
                  setModalesAbiertos(
                    modalesAbiertos.filter(
                      (modal_a) => modal_a !== "certificacion"
                    )
                  );
                  Services.getAllMiembros((response) => {
                    response.then((data) => {
                      setData(data);
                    });
                  });
                }
              );
            },
          },
        ],
      },
      tipo: "tabla",
      seccion: "1",
    },
  ];
  return (
    <>
      <div className="row w100 hcenter">
        <div className="row mxw1000px wrap">
          <div className="col-12 md:col-8">
            <h1>Plan de Acción</h1>
          </div>
          <div className="row w100 flex">
            <div className="row w100">
              <div className="col-12 md:col-9">
                <p>
                  Son todas las actividades, cursos o certificaciones asignadas
                  para el gestor que deben ser completadas en el tiempo indicado
                </p>
              </div>
            </div>

            <div className="col-12 md:col-3 row hright">
              <button
                onClick={() => modales[0].onClick(modales[0])}
                className="btn_agregar"
              >
                Agregar acción
              </button>
            </div>
          </div>

          <PlanAccionTable empleadoDetalle={empleadoDetalle} setData={setData} />
        </div>
      </div>
      {modales.map((modal) => (
        <Dialog
          key={modal.name}
          header={`${modal.add ? "Agregar " : ""}${modal.name}`}
          visible={modalesAbiertos.includes(modal.name)}
          onHide={() => {
            setModalesAbiertos(
              modalesAbiertos.filter((modal_a) => modal_a !== modal.name)
            );
          }}
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <ModalForm modal={modal} dinamicValues={{}} />
        </Dialog>
      ))}
    </>
  );
};

export default PlanAccion;
