import { Dialog } from "primereact/dialog";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import TableActividades from "../../../Atoms/TableActividades";
import Actions from "../../../Organism/Actions";
import ModalForm from "../../../Organism/ModalForm";
import { Services } from "../Services";
import { ServiceMetas } from "./Service";
import Expertise from "./Components/Expertise";
import Indicadores from "./Components/Indicadores";
import Presupuesto from "./Components/Presupuesto";
export default function Metas() {
  const [dinamicValues, setDibamicValues] = useState({ actividades: [] });
  const opciones = [
    {
      name: "Expertises",
      path: "Expertise",
      onClick: (opcion) => {
        setCurrentOption(opcion);
      },
      print: "Expertises",
      component: () => <Expertise />,
      add: false,
      // Mauricio
      tipo: "tabla",
      //
      seccion: "8",
      modales: [
        {
          name: "Expertises",
          path: "Expertises",
          onClick: (opcion) => {
            setModalesAbiertos([...modalesAbiertos, opcion.name]);
          },
          print: "Agregar Expertise",
          add: true,
          isModal: true,
          component: null,
          formData: {
            body: {
              nombre: "",
              categoria: "",
              duracion: [],
            },
            endpoint: "",
            fields: [
              {
                type: "textField",
                name: "nombre",
                text: "Nombre del expertise",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "select",
                name: "categoria",
                text: "Categoría",
                value: "",
                method: "/CategoriaDeCertificaciones/getCategorias/",
                getByEndPont: true,
                onClick: () => {},
              },
              {
                type: "dateField",
                name: "duracion",
                text: "Duración del expertise",
                value: [],
                selectionMode: "range",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "custom",
                name: "actividades",
                text: "Actividades asignadas al equipo",
                value: [],
                method: "",
                getByEndPont: false,
                onClick: (body) => {},

                component: (value, setValue) => {
                  return (
                    <TableActividades
                      canAdd={true}
                      value={value}
                      setValue={setValue}
                      fields={[
                        { name: "nombre", header: "Nombre", type: "textField" },
                        {
                          name: "numero",
                          header: "N. requeridos",
                          type: "numberField",
                        },
                        {
                          name: "certificacion",
                          header: "Certificación",
                          type: "select",
                          options: [],
                          method: "/Certificaciones/getCertificaciones/",
                          getByEndPont: true,
                        },
                      ]}
                      body={{ nombre: "", numero: 0, certificacion: "" }}
                      onChange={(data) => {
                        var dinamicCP = dinamicValues;
                        if (dinamicValues["actividades"]) {
                          dinamicCP["actividades"] = data;
                        }
                        console.log(dinamicCP);
                        setDibamicValues(dinamicCP);
                      }}
                    />
                  );
                },
              },

              {
                type: "button",
                name: "submit",
                text: "Crear expertise",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: (body) => {
                  Services.createExpertise(body, (response) => {
                    toast.success("Expertise creado");
                    setModalesAbiertos(
                      modalesAbiertos.filter(
                        (modal_a) => modal_a !== "Expertises"
                      )
                    );
                  });
                },
              },
            ],
          },
          tipo: "tabla",
          seccion: "1",
        },
      ],
      //
    },
    {
      name: "Presupuesto",
      path: "Presupuesto",
      print: "Presupuesto",
      add: false,
      component: () => <Presupuesto />,
      modales: [
        {
          name: "Presupuestos",
          path: "Presupuestos",
          onClick: (opcion) => {
            setModalesAbiertos([...modalesAbiertos, opcion.name]);
          },
          print: "Agregar Presupuestos",
          add: true,
          isModal: true,
          component: null,
          formData: {
            body: {
              name: "",
              monto: "",
              status: "",
              comentario:""
            },
            endpoint: "",
            fields: [
              {
                type: "textField",
                name: "name",
                text: "Nombre",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "numberField",
                name: "monto",
                text: "Monto",
                value: 0.0,
                method: "",
                mode:"currency",
                currency:"USD",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "select",
                name: "status",
                text: "Estatus",
                value: "",
                method: "/presupuesto/getStatus/",
                getByEndPont: true,
                onClick: () => {},
              },
              {
                type: "textArea",
                name: "comentario",
                text: "Comentario",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "button",
                name: "submit",
                text: "Crear",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: (body) => {
                  ServiceMetas.createPresupuesto(body, () => {
                    toast.success("Presupuesto creado");
                    setModalesAbiertos(
                      modalesAbiertos.filter((modal_a) => modal_a !== "Presupuesto")
                    );
                  });
                },
              },
            ],
          },
          tipo: "tabla",
          seccion: "1",
        },
      ],
      // Mauricio
      tipo: "tabla",
      //
      onClick: (opcion) => {
        setCurrentOption(opcion);
      },
      seccion: "9",
      //
    },
    /*
    {
      name: "Indicadores",
      path: "Expertise-indicadores",
      print: "Indicadores",
      add: false,
      component: () => <Indicadores />,
      modales: [
        {
          name: "Indicadores",
          path: "Indicadores",
          onClick: (opcion) => {
            setModalesAbiertos([...modalesAbiertos, opcion.name]);
          },
          print: "Agregar Indicadores",
          add: true,
          isModal: true,
          component: null,
          formData: {
            body: {
              clave: "",
              nombre: "",
              integrantes: [],
            },
            endpoint: "",
            fields: [
              {
                type: "textField",
                name: "clave",
                text: "Clave",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "textField",
                name: "nombre",
                text: "Nombre del equipo",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "multiSelect",
                name: "integrantes",
                text: "Empleados",
                value: [],
                method: "/EquiposSeleccion/getEmpleados/",
                getByEndPont: true,
                onClick: () => {},
              },
              {
                type: "button",
                name: "submit",
                text: "Crear",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: (body) => {
                  Services.create_equipo(body, () => {
                    toast.success("Equipo creado");
                    setModalesAbiertos(
                      modalesAbiertos.filter((modal_a) => modal_a !== "Equipos")
                    );
                  });
                },
              },
            ],
          },
          tipo: "tabla",
          seccion: "1",
        },
      ],
      // Mauricio
      tipo: "tabla",
      //
      onClick: (opcion) => {
        setCurrentOption(opcion);
      },
      seccion: "10",
      //
    },*/
  ];
  const [currentOption, setCurrentOption] = useState(opciones[0]);
  const [modalesAbiertos, setModalesAbiertos] = useState([]);

  useEffect(() => {
    for (const modal_no_abierto of currentOption.modales.filter(
      (modal) => !modalesAbiertos.includes(modal.name)
    )) {
      for (const field of modal_no_abierto.formData.fields) {
        var dinamicCP = dinamicValues;
        if (dinamicValues[field.name]) {
          dinamicCP[field.name] = field.value;
        }
        setDibamicValues(dinamicCP);
      }
    }
  }, [modalesAbiertos]);
  useEffect(() => {}, [currentOption]);
  return (
    <>
      <Actions
        currentOption={currentOption}
        opciones={opciones}
        modales={currentOption.modales}
      />
      {currentOption.component()}
      {currentOption.modales.map((modal) => (
        <Dialog
          key={modal.name}
          header={`${modal.add ? "Agregar " : ""}${modal.name}`}
          visible={modalesAbiertos.includes(modal.name)}
          onHide={() => {
            setModalesAbiertos(
              modalesAbiertos.filter((modal_a) => modal_a !== modal.name)
            );
          }}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <ModalForm modal={modal} dinamicValues={dinamicValues} />
        </Dialog>
      ))}
    </>
  );
}
