import { Dialog } from "primereact/dialog";
import ModalForm from "../../../../Organism/ModalForm";
import { useState } from "react";
import { ServiceMetas } from "../Service";
import { Panel } from 'primereact/panel';
import { toast } from "react-toastify";
export default function PresupuestoDetails({ show, presupuesto, onHide }) {
    const [dinamicValues, setDibamicValues] = useState({});
    const modal = {
        name: "Presupuestos",
        path: "Presupuestos",
        print: "Editar Presupuesto",
        add: true,
        isModal: true,
        component: null,
        formData: {
            body: {
                uuid: presupuesto?.uuid,
                name: presupuesto?.name,
                monto: presupuesto?.monto,
                status: presupuesto?.status,
                comentario: ""
            },
            endpoint: "",
            fields: [
                {
                    type: "textField",
                    name: "name",
                    text: "Nombre",
                    value: presupuesto?.name,
                    method: "",
                    getByEndPont: false,
                    onClick: () => { },
                },
                {
                    type: "numberField",
                    name: "monto",
                    text: "Monto",
                    value: presupuesto?.monto,
                    method: "",
                    mode: "currency",
                    currency: "USD",
                    getByEndPont: false,
                    onClick: () => { },
                },
                {
                    type: "select",
                    name: "status",
                    text: "Estatus",
                    value: presupuesto?.status,
                    method: "/presupuesto/getStatus/",
                    getByEndPont: true,
                    onClick: () => { },
                },
                {
                    type: "custom",
                    name: "comentarios",
                    text: "Comentarios",
                    value: presupuesto?.comentarios,
                    method: "",
                    getByEndPont: false,
                    onClick: (body) => { },

                    component: () => {
                        return (

                            <>
                                {presupuesto.comentarios.map(comentario => <Panel header={comentario.created} className="w100" style={{ marginBottom: 10 }} >
                                    <p className="m-0">
                                        {comentario.comentario}
                                    </p>
                                </Panel>)}
                            </>
                        );
                    },
                },
                {
                    type: "textArea",
                    name: "comentario",
                    text: "Comentario",
                    value: "",
                    method: "",
                    getByEndPont: false,
                    onClick: () => { },
                },
                {
                    type: "button",
                    name: "submit",
                    text: "Actualizar",
                    value: "",
                    method: "",
                    getByEndPont: false,
                    onClick: (body) => {
                        ServiceMetas.updatePresupuesto(body, () => {
                            toast.success("Presupuesto creado");
                            onHide()
                        });
                    },
                },
            ],
        },
        tipo: "tabla",
        seccion: "1",
    }
    return (
        <Dialog
            header={presupuesto?.name}
            visible={show}
            onHide={() => onHide()}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
            <ModalForm modal={modal} dinamicValues={dinamicValues} />

        </Dialog>
    )
}