import { ReactSVG } from "react-svg";
export default function ItemModule({ setApp, menu, currentMenu, svg, text }) {
  return (
    <div
      className={`menu_option_hover pointier w100 row wrap`}
      onClick={() => {
        setApp(menu);
      }}
    >
      <div className="row hcenter w100">
        <div
          className={`menu_option pointier ${
            currentMenu.menu === menu.menu ? "active" : ""
          }`}
        >
          <ReactSVG src={svg}  />
        </div>
      </div>
      <div
        className="row hcenter mtop15 bold w100 t_center"
        style={{ height: 30 }}
      >
        {text}
      </div>
    </div>
  );
}
