import "./App.css";
import Login from "./Components/Login";
import Main from "./Components/Main";
import Restart from "./Components/Restart";
import "./static/scss/styles.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// Mauricio
export const URL = process.env.REACT_APP_API_URL || "portal.eks-solutions.com:8000"
export const baseURL = process.env.REACT_APP_API_URL_COMPLETE|| `http://${URL}`;
export var USER = {}
export const axios_instance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
});
function pass(){}
const firebaseConfig = {
  apiKey: "AIzaSyB53R6oSWAjaOkR9Gfe7mrA6TGZZQPYfjs",
  authDomain: "sct-eks.firebaseapp.com",
  projectId: "sct-eks",
  storageBucket: "sct-eks.appspot.com",
  messagingSenderId: "753294847564",
  appId: "1:753294847564:web:5b42d4eda8153b1230cee9",
  measurementId: "G-YM20J7XMPT"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const Post = (
  method,
  ok_action,
  error_action,
  show_loader,
  message,
  params,
  multi
) => {
  var id = null;
  if (show_loader) id = toast.loading(message, { toastId: method,theme:"dark" });
  if (params == null) {
    params = new URLSearchParams();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
   
  }

  axios_instance
    .post(method, params,multi?{
      headers: {
        "Content-Type": "multipart/form-data",
      }}:{},)
    .then(function (response) {
      if (show_loader)
      toast.update(id, {
        render: response.data.message,
        type: "success",
        isLoading: false,
        autoClose: true,
        toastId: response.data.message,
        theme:'dark'
      });
      ok_action(response);
    })
    .catch(function (error) {

      if(error.response)
      toast.update(id, {
        render: `${error.response.status} ${error.response.data.detail.message}`,
        type: "error",
        isLoading: false,
        autoClose: true,
        toastId: `${error.response.status} ${error.response.data.detail.message}`,
      });
      else
      toast.update(id, {
        render: `${error.message}`,
        type: "error",
        isLoading: false,
        autoClose: true,
        toastId: `${error.message}`,
      });
      error_action(error);
    });
};

export const Get = (
  method,
  ok_action,
  error_action,
  show_loader,
  message,
  params
) => {
  var id = null;
  if (show_loader) id = toast.loading(message, { toastId: method, theme:"dark" });
  if (params == null) {
    params = new URLSearchParams();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
  }
  axios_instance
    .get(method, params)
    .then(function (response) {
      toast.update(id, {
        render: response.data.message,
        type: "success",
        isLoading: false,
        autoClose: true,
        toastId: response.data.message,
      });
      ok_action(response);
    })
    .catch(function (error) {

      toast.update(id, {
        render: `${error.response.status} ${error.response.statusText}`,
        type: "error",
        isLoading: false,
        autoClose: true,
        toastId: `${error.response.status} ${error.response.statusText}`,
      });
      error_action(error);
    });
};


function Base({ Component, Post, user, setUser, Get }) {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Component toast={toast} Post={Post} user={user} setUser={setUser} Get={Get} />
    </>
  );
}

function App() {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(true);
  const [loaded,setLoaded]=useState(false)
  //Configuración de peticiones
  
  const Get = (
    method,
    ok_action,
    error_action,
    show_loader,
    message,
    params
  ) => {
    var id = null;
    if (show_loader) id = toast.loading(message, { toastId: method, theme:"dark" });
    if (params == null) {
      params = new URLSearchParams();
      params.append(
        "public_key",
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
      );
    }
    axios_instance
      .get(method, params)
      .then(function (response) {
        toast.update(id, {
          render: response.data.message,
          type: "success",
          isLoading: false,
          autoClose: true,
          toastId: response.data.message,
        });
        ok_action(response);
      })
      .catch(function (error) {
       
        toast.update(id, {
          render: `${error.response.status} ${error.response.statusText}`,
          type: "error",
          isLoading: false,
          autoClose: true,
          toastId: `${error.response.status} ${error.response.statusText}`,
        });
        error_action(error);
      });
  };

  useEffect(() => {
    Post(
      "/check_token",
      (response) => {
        setLoader(false);
   
        setUser(response.data.user)
      },
      (error) => {
        setLoader(false);
        setUser(null)
      },
      false,
      "Prueba",
      null
    );
  }, []);

  useEffect(() => {
  }, [loader]);

  useEffect(() => {
    if(user==null && loaded){
      Get("logout",(response)=>(pass("")),(error)=>(pass("")),true,"Saliendo",null)
      setLoaded(false)
    }
    if(user){
      setLoaded(true)
      USER=user}
  }, [user]);

  if (loader)
    return (
      <div className="h100vh row center hcenter vcenter">
        <ThreeCircles
          height="60"
          width="60"
          color="#0061FF"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
      </div>
    );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Base Component={Login} Post={Post} user={user} setUser={setUser} />} />
        <Route path="/" element={<Base Component={Main} Post={Post} user={user} setUser={setUser} Get={Get} />} />
        <Route path="/restart" element={<Base Component={Restart} Post={Post} user={user} setUser={setUser} Get={Get} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
