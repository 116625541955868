import { useState } from "react";
import { ServicesDashboard } from "../Services";
import { useEffect } from "react";
import Container from "../../../../Atoms/Container"
import Loader from "../../../../Atoms/Loader";
import { Calendar } from 'primereact/calendar';
import Chart from "react-apexcharts";
import { Card } from 'primereact/card';

export default function DashCertificaciones() {
    const [data, setData] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 5,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                type: 'datetime',
                categories: [],
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            }
        },
    }
    )
    const [loading, setLoading] = useState(true)
    const [dates, setDates] = useState([])
    useEffect(() => {
        setLoading(true)
        ServicesDashboard.getCertificacionesDashboard((response) => {
            response.then((data) => {
                console.log(data);
                setData({
                    series: data.datos,
                    options: {
                        chart: {
                            type: 'bar',
                            height: 350,
                            stacked: true,
                            toolbar: {
                                show: true
                            },
                            zoom: {
                                enabled: true
                            }
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: 'bottom',
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }],
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 5,
                                dataLabels: {
                                    total: {
                                        enabled: true,
                                        style: {
                                            fontSize: '13px',
                                            fontWeight: 900
                                        }
                                    }
                                }
                            },
                        },
                        xaxis: {
                            type: 'datetime',
                            categories: data.categorias,
                        },
                        legend: {
                            position: 'right',
                            offsetY: 40
                        },
                        fill: {
                            opacity: 1
                        }
                    },
                });
                setLoading(false)

            });
        },{})
    }, [])
    useEffect(() => {
        
        if(dates[0] && dates[1]){
            setLoading(true)
            ServicesDashboard.getCertificacionesDashboard((response) => {
                response.then((data) => {
                    console.log(data);
                    setData({
                        series: data.datos,
                        options: {
                            chart: {
                                type: 'bar',
                                height: 350,
                                stacked: true,
                                toolbar: {
                                    show: true
                                },
                                zoom: {
                                    enabled: true
                                }
                            },
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    legend: {
                                        position: 'bottom',
                                        offsetX: -10,
                                        offsetY: 0
                                    }
                                }
                            }],
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    borderRadius: 5,
                                    dataLabels: {
                                        total: {
                                            enabled: true,
                                            style: {
                                                fontSize: '13px',
                                                fontWeight: 900
                                            }
                                        }
                                    }
                                },
                            },
                            xaxis: {
                                type: 'datetime',
                                categories: data.categorias,
                            },
                            legend: {
                                position: 'right',
                                offsetY: 40
                            },
                            fill: {
                                opacity: 1
                            }
                        },
                    });
                    setLoading(false)
    
                });
            },{dates:dates})
        }
       
    }, [dates])
    return (
        <Container>
            <div className="row w100 hcenter">
            <Card>
                <div className="row w100 hright mb15">
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection showIcon placeholder="Seleccionar fechas" style={{ height: 32 }} />
                </div>
                <div className="row w100 hcenter wrap">
                    {loading ? <Loader /> :
                        <Chart
                            options={data?.options}
                            series={data?.series}
                            type="bar"
                            width="650"
                        />
                    }
                </div>
            </Card>
            </div>
        </Container>
    )
}