import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { toast } from "react-toastify";
import { Card } from "primereact/card";
import { Post } from "../../App";

import { Skeleton } from 'primereact/skeleton';
function RenderField({ field, addingRow, setAddingRow }) {
  const [listValues, setListValues] = useState(
    field.options ? field.options : []
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {}, [loading]);
  useEffect(() => {}, [listValues]);
  useEffect(() => {
    if (field.getByEndPont && !loading) {
      var params = {
        public_key:
          "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",

        private_key:
          "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
      };
      setLoading(true);
      Post(
        field.method,
        (response) => {
          console.log(response);
          if (field.type === "multiSelect" || field.type === "select")
            setListValues(response.data);
          setLoading(false);
        },
        (error) => {},
        true,
        "Cargando datos",
        params
      );
    }
  }, []);

  function changeValue(value) {
    let _addingRow = { ...addingRow };
    _addingRow[field.name] = value;
    setAddingRow(_addingRow);
  }

  useEffect(() => {}, [addingRow]);
  switch (field.type) {
    case "textField":
      return (
        <>
          <InputText
            value={addingRow[field.name]}
            placeholder={field.header}
            onChange={(event) => {
              changeValue(event.target.value);
            }}
            name={field.name}
            type="text"
            style={{ marginRight: 5 ,width: "12rem" }}
          />
          <br />
        </>
      );
    case "numberField":
      return (
        <>
          <InputNumber
            value={addingRow[field.name]}
            placeholder={field.header}
            onChange={(event) => {
              changeValue(event.value);
            }}
            name={field.name}
            style={{ marginRight: 5,width: "3rem" }}
          />
          <br />
        </>
      );
    case "select":
      return (
        <>
          <Dropdown
            value={addingRow[field.name]}
            options={listValues}
            onChange={(event) => {
              changeValue(event.value);
            }}
            placeholder={field.header}
            emptyFilterMessage={`Dato no encontrado`}
            optionLabel="name"
            optionValue="code"
            style={{ marginRight: 5,width: "12rem" }}
            filter
            display="chip"
          />
        </>
      );
    default:
      break;
  }
}

function RenderEditor({ field, options }) {
  const [listValues, setListValues] = useState(
    field.options ? field.options : []
  );
  const [loading, setLoading] = useState(false);
  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        style={{width: "12rem"}}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const numberEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        style={{width: "3rem"}}
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  };

  const selectEditor = (field, options) => {
    return (
      <Dropdown
        value={options.value}
        options={field.getByEndPont?listValues:field.options}
        placeholder={field.header}
        emptyFilterMessage={`Dato no encontrado`}
        optionLabel="name"
        optionValue="code"
        style={{width: "12rem"}}
        filter
        display="chip"
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  };
  useEffect(() => {}, [loading]);
  useEffect(() => {}, [listValues]);
  useEffect(() => {
    if (field.getByEndPont && !loading) {
      var params = {
        public_key:
          "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",

        private_key:
          "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
      };
      setLoading(true);
      Post(
        field.method,
        (response) => {
          console.log(response);
          if (field.type === "multiSelect" || field.type === "select")
            setListValues(response.data);
          setLoading(false);
        },
        (error) => {},
        true,
        "Cargando datos",
        params
      );
    }
  }, []);

  if(field.getByEndPont && loading)
    return <Skeleton height="2rem" className="mb-2"></Skeleton>

  switch (field.type) {
    case "textField":
      return textEditor(options);
    case "numberField":
      return numberEditor(options);
    case "select":
      return selectEditor(field, options);
  }
}

export default function TableActividades({
  fields,
  value,
  canAdd,
  onChange,
  setValue,
  body,
}) {
  const [products, setProducts] = useState(value);
  const [addingRow, setAddingRow] = useState(body);

  useEffect(() => {
    setValue(products);
  }, [products]);
  const onRowEditComplete = (e) => {
    console.log(e);
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const edit = (field, options) => (
    <RenderEditor field={field} options={options} />
  );

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  const add = () => {
    // Verifica si algún valor en el objeto es nulo o una cadena vacía
    if (
      !Object.values(addingRow).some((value) => value === null || value === "")
    ) {
      // Agrega el objeto al array de productos
      setProducts([...products, addingRow]);

      // Reinicia los valores de addingRow a cadenas vacías
      setAddingRow(body);
    } else {
      toast.warning("Llena todos los campos", { theme: "dark" });
    }
  };

  const remove = (data) => {
    setProducts(products.filter((row) => row.nombre !== data.nombre));
  };

  const removeRender = (data) => (
    <i
      className="fa-solid fa-minus pointier"
      style={{
        padding: 10,
        borderRadius: 50,
        border: "1px solid rgb(205, 205, 205)",
        color: "grey",
      }}
      onClick={() => remove(data)}
    ></i>
  );

  return (
    <div className="mtop15">
      <Card>
        <DataTable
          value={products}
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
        >
          {fields.map((field) => (
            <Column
              key={field.name}
              field={field.name}
              header={field.header}
            
              editor={(options) => edit(field, options)}
            ></Column>
          ))}
          <Column
            rowEditor={allowEdit}
            headerStyle={{ width: "10%", minWidth: "7rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            bodyStyle={{ textAlign: "center" }}
            body={removeRender}
          >
          </Column>
        </DataTable>
        {canAdd && (
          <div className="row ">
            {fields.map((field) => (
              <RenderField
                key={field.name}
                field={field}
                setAddingRow={setAddingRow}
                addingRow={addingRow}
              />
            ))}
            <i
              className="fa-solid fa-plus pointier"
              style={{
                padding: 10,
                borderRadius: 50,
                border: "1px solid rgb(205, 205, 205)",
                color: "grey",
              }}
              onClick={add}
            ></i>
          </div>
        )}
      </Card>
    </div>
  );
}
