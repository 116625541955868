import { Button } from "primereact/button";
export default function Reload({ onClick }) {
    return (
        <Button
            onClick={onClick}
            tooltip="Refrescar"
            icon="fa-solid fa-rotate"
            className="btn_calificar"
            style={{ fontSize: "18px", height: 30, width: 30 }}
            severity="info"
            text
            raised
        />
    )
}