import DashEquipo from "./Components/DashEquipo";
import DashColaborador from "./Components/DashColaborador";
import DashCertificaciones from "./Components/DashCertificaciones";
import { useState,useEffect } from "react";
import Actions from "../../../Organism/Actions";
export default function Dashboard() {
  const [dinamicValues, setDibamicValues] = useState({})
  const opciones = [
    {
      name: "Equipo",
      path: "Equipo",
      onClick: (opcion) => {
        setCurrentOption(opcion)
      },
      print: "Equipo",
      component: ()=><DashEquipo />,
      tipo: "tabla",
      seccion: "1",
      modales: [
      ]
    },
    {
      name: "Colaborador",
      path: "Colaborador",
      onClick: (opcion) => {
        setCurrentOption(opcion)
      },
      print: "Colaborador",
      component: ()=> <DashColaborador />,
      tipo: "tabla",
      seccion: "1",
      modales: [
      ]
    },
    {
      name: "Certificaciones",
      path: "Certificaciones",
      onClick: (opcion) => {
        setCurrentOption(opcion)
      },
      print: "Certificaciones",
      component: ()=> <DashCertificaciones />,
      tipo: "tabla",
      seccion: "1",
      modales: [
      ]
    },
    /*
    {
      name: "Expertises",
      path: "Expertises",
      onClick: (opcion) => {
        setCurrentOption(opcion)
      },
      print: "Expertises",
      component: DashEquipo,
      tipo: "tabla",
      seccion: "1",
      modales: [
      ]
    }*/

  ]
  const [currentOption, setCurrentOption] = useState(opciones[0]);
  const [modalesAbiertos, setModalesAbiertos] = useState([]);
  useEffect(() => {
    for (const modal_no_abierto of currentOption.modales.filter((modal) => !modalesAbiertos.includes(modal.name))) {
      for (const field of modal_no_abierto.formData.fields) {
        var dinamicCP = dinamicValues
        if (dinamicValues[field.name]) {
          dinamicCP[field.name] = field.value
        }
        setDibamicValues(dinamicCP)
      }
    }
  }, [modalesAbiertos])
  useEffect(() => { }, [currentOption])
  return (
    <>
      <Actions currentOption={currentOption} opciones={opciones} modales={currentOption.modales} />
      {currentOption.component()}
    </>
  )
}