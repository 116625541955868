import { Icon } from "@iconify/react";
import useCollapse from "react-collapsed";
import { Zoom } from "react-reveal";
import { InputNumber } from "primereact/inputnumber";
import { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import { ToastContainer, toast } from "react-toastify";
function pass() { }
function Actividad({
  actividad,
  actividades,
  setActividades,
  inputRef,
  setInputFocus,
  id,
  categoriasList,
  categorias,
  setCategorias
}) {
  var t_horas = 0;
  for (let i in actividades) {
    t_horas += actividades[i].n_horas;
  }
  
  pass(t_horas);
  pass(actividad.n_horas);
  var a = 8 - (t_horas - actividad.n_horas);
  pass(a);
  const [edit, setEdit] = useState(actividad.edit);
  const [editRow, setEditRow] = useState({});
  const [max, setMax] = useState(a);
  const [click, setClick] = useState(false)
  const [hand, setHand] = useState(false)
  const [newRow, setNewRow] = useState({
    actividad: "",
    categoria: "",
    n_horas: 0,
  });

  useEffect(() => {

    var t_horas = 0;
    for (let i in actividades) {
      t_horas += actividades[i].n_horas;
    }
    pass(t_horas);
    pass(actividad.n_horas);
    var a = 8 - (t_horas - actividad.n_horas);
    pass(a);
    setMax(a);
  }, [newRow]);

  useEffect(() => {

      addActividad()
  }, [click]);

  useEffect(() => {
    var t_horas = 0;
    for (let i in actividades) {
      t_horas += actividades[i].n_horas;
    }
    pass(t_horas);
    pass(actividad.n_horas);
    var a = 8 - (t_horas - actividad.n_horas);
    pass(a);
    setMax(a);
  }, [edit]);

  useEffect(() => {
    pass(editRow);
  }, [editRow]);

  useEffect(() => {
    pass(max);
  }, [max]);

  function enterNew(e) {
    if (e.code == "Enter") {
      if (newRow.actividad.trim().length > 0 && newRow.n_horas > 0) {
        if(!categorias.includes(newRow.categoria.toUpperCase())&&newRow.categoria!="")
          setCategorias([...categorias,newRow.categoria])
        let obj = newRow;
        let actividadesCP = [...actividades];
        actividadesCP.push(obj);
        setActividades(actividadesCP);
        setNewRow({
          actividad: "",
          categoria: "",
          n_horas: 0,
        });

        document.getElementById(id).focus();
      }
    }
  }

  function enterUpdate(e) {
    if (e.code == "Enter") {
      if (editRow.actividad.trim().length > 0 && editRow.n_horas > 0) {
        let obj = editRow;
        let actividadesCP = [...actividades];
        for (let i in actividadesCP) {
          pass(actividadesCP[i]);
          if (actividad == actividadesCP[i]) {
            actividadesCP[i] = obj;
            break;
          }
        }
        setActividades(actividadesCP);
        setEdit(false);
      }
    }
  }

  function addActividad() {

    if (
      newRow.actividad.trim().length > 0 &&
      newRow.n_horas > 0
    ) {
      if(!categorias.includes(newRow.categoria.toUpperCase())&&newRow.categoria!="")
        setCategorias([...categorias,newRow.categoria.toUpperCase()])
      let obj = newRow;
      let actividadesCP = [...actividades];
      actividadesCP.push(obj);
      setActividades(actividadesCP);
      setNewRow({
        actividad: "",
        categoria: "",
        n_horas: 0,
      });

      document.getElementById(id).focus();
    } else {
      if (newRow.actividad.length > 0 || newRow.categoria.length > 0 || newRow.n_horas > 0)
        toast.error(`Verifica que los campos esten completos.`, { theme: "dark", toastId: "llenar" });
    }
  }

  useEffect(() => {
    if (id)
      document.getElementById("root").addEventListener("click", (event) => {
        if (event.target.tagName != "svg" & event.target.tagName != "INPUT" & !event.target.className.includes("p-button-icon") & !event.target.className.includes("p-dropdown-trigger-icon")) {
          setClick(!click)
        }
      })
  }, [newRow])

  if (edit)
    if (actividad.isAdd) {
      var t_horas = 0;
      for (let i in actividades) {
        t_horas += actividades[i].n_horas;
      }

      if (t_horas >= 8) return;

      return (
        <tr className="row_table">
          <td className="column">
            <Dropdown value={newRow.categoria} onChange={(e) => {
              var valor = ""
              if (e.originalEvent) valor = e.value.name
              else valor = e.value

              let cpnewRow = { ...newRow };
              cpnewRow.categoria = valor.toUpperCase();
              setNewRow(cpnewRow);

            }} options={categoriasList} optionLabel="name"
              editable placeholder="Categoría" className="w-full md:w-14rem" />

          </td>
          <td className="column">
            <div className="row vcenter">
              {/*<Icon
                icon={"material-symbols:add-circle"}
                className="fontSizeMd  pointier c_color"
                id={`addBtn${id}`}
                style={{ marginRight: 5 }}
              
                onClick={() => {
                  
                  if (
                    newRow.actividad.trim().length > 0 &&
                    newRow.n_horas > 0
                  ) {
                    let obj = newRow;
                    let actividadesCP = [...actividades];
                    actividadesCP.push(obj);
                    setActividades(actividadesCP);
                    setNewRow({
                      actividad: "",
                      fase: "",
                      modulo: "",
                      n_horas: 0,
                    });

                    document.getElementById(id).focus();
                  }
                }}
              />*/}
              <input
                className="input_actividad w100 required"
                value={newRow.actividad}
                required
                placeholder="Actividad*"
                id={id}
                type="text"
                autoFocus
                onChange={(e) => {
                  pass(e);
                  if (e.target.validity.valid || e.target.value == "") {
                    let cpnewRow = { ...newRow };
                    cpnewRow.actividad = e.target.value;
                    setNewRow(cpnewRow);
                  }
                }}
                onKeyDown={(e) => {
                  enterNew(e);
                }}
              />
            </div>
          </td>


          <td className="column" style={{ maxWidth: 100 }}>
            <InputNumber
              value={newRow.n_horas}
              style={{ maxWidth: 100 }}
              onKeyDown={(e) => {
                enterNew(e);
              }}
              onValueChange={(e) => {
                let cpnewRow = { ...newRow };
                var t_horas = 0;
                for (let i in actividades) {
                  t_horas += actividades[i].n_horas;
                }

                t_horas += parseInt(e.value);

                t_horas -= actividad.n_horas;
                if (t_horas > 8) return;

                cpnewRow.n_horas = parseInt(e.value);
                setNewRow(cpnewRow);
              }}
              mode="decimal"
              useGrouping={false}
              showButtons
              min={0}
              max={max}
            />


          </td>
          <td className="column">
            <div className="row vcenter hcenter"></div>
          </td>
          <td className="column"></td>
        </tr>
      );
    } else {
      return (
        <tr className="row_table">
          <td className="column">
          <Dropdown value={editRow.categoria} 
          onChange={(e) => {
              var valor = ""
              if (e.originalEvent) valor = e.value.name
              else valor = e.value

              let cpnewRow = { ...editRow };
              cpnewRow.categoria = valor.toUpperCase();
              setEditRow(cpnewRow);

            }} options={categoriasList} optionLabel="name"
              editable placeholder="Categoría" className="w-full md:w-14rem" />
          </td>
          <td className="column">
            <div className="row vcenter">
              <input
                className="input_actividad w100 required"
                type="text"
                value={editRow.actividad}
                onChange={(e) => {
                  if (e.target.validity.valid || e.target.value == "") {
                    let cpnewRow = { ...editRow };
                    cpnewRow.actividad = e.target.value;
                    setEditRow(cpnewRow);
                  }
                }}
                onKeyDown={(e) => {
                  enterUpdate(e);
                }}
              />
            </div>
          </td>


          <td className="column" style={{ maxWidth: 100 }}>
            <InputNumber
              value={actividad.n_horas}
              style={{ maxWidth: 100 }}
              onKeyDown={(e) => {
                if (
                  editRow.actividad.trim().length > 0 &&
                  editRow.n_horas > 0
                ) {
                  let obj = editRow;
                  let actividadesCP = [...actividades];
                  for (let i in actividadesCP) {
                    pass(actividadesCP[i]);
                    if (actividad == actividadesCP[i]) {
                      actividadesCP[i] = obj;
                      break;
                    }
                  }
                  setActividades(actividadesCP);
                  setEdit(false);
                }
              }}
              onValueChange={(e) => {
                let cpnewRow = { ...editRow };
                var t_horas = 0;
                for (let i in actividades) {
                  t_horas += actividades[i].n_horas;
                }

                t_horas += parseInt(e.value);

                t_horas -= actividad.n_horas;
                if (t_horas > 8) return;
                cpnewRow.n_horas = parseInt(e.value);
                setEditRow(cpnewRow);
              }}
              mode="decimal"
              useGrouping={false}
              showButtons
              min={1}
              max={max}
            />
          </td>
          <td className="column">
            <div className="row vcenter hcenter">
              <Icon
                icon={"material-symbols:edit-square-outline"}
                className="fontSizeMd hover_green pointier"
                onClick={() => {
                  if (
                    editRow.actividad.trim().length > 0 &&
                    editRow.n_horas > 0
                  ) {
                    let obj = editRow;
                    let actividadesCP = [...actividades];
                    for (let i in actividadesCP) {
                      pass(actividadesCP[i]);
                      if (actividad == actividadesCP[i]) {
                        actividadesCP[i] = obj;
                        break;
                      }
                    }
                    setActividades(actividadesCP);
                    setEdit(false);
                  }
                }}
              />
            </div>
          </td>
          <td className="column">
            <div className="row vcenter hcenter">
              <Icon
                icon={"bi:trash"}
                className="fontSizeMd hover_green pointier"
                onClick={() => setEdit(true)}
              />
            </div>
          </td>
        </tr>
      );
    }

  return (
    <tr className="row_table">
      <td className="column">{actividad.categoria}</td>

      <td className="column">{actividad.actividad}</td>

      <td className="column">{actividad.n_horas}</td>
      <td className="column">
        <div className="row vcenter hcenter">
          <Icon
            icon={"material-symbols:edit-square-outline"}
            className="fontSizeMd hover_green pointier"
            onClick={() => {
              setEdit(true);
              setEditRow(actividad);
            }}
          />
        </div>
      </td>
      <td className="column">
        <div className="row vcenter hcenter">
          <Icon
            icon={"bi:trash"}
            className="fontSizeMd hover_green pointier"
            onClick={() => {
              let cpArray = actividades.filter(
                (actividadO) => actividadO != actividad
              );
              setActividades(cpArray);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

export default function RowPeriodoInterno({
  dia,
  setDias,
  dias,
  inputRef,
  setInputFocus,
  categoriasList,
  categorias,
  setCategorias

}) {
  const [isExpanded, setExpanded] = useState(false);
  const [actividades, setActividades] = useState([...dia.actividades]);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });
  useEffect(() => {
    let diasO = [...dias];
    for (let i in diasO) {
      if (diasO[i] == dia) {
        diasO[i].actividades = actividades ? actividades : [];
        setDias(diasO);
      }
    }
  }, [actividades]);

  useEffect(() => { }, [isExpanded]);

  return (
    <div className="row w100 hcenter mtop_7px">
      <div className="w100  row vcenter hspace">
        <div className="box_c w100">
          <div
            className={`date_row w100 white hspace vcenter pointier ${isExpanded ? "active" : ""
              }`}
            {...getToggleProps({
              style: { display: "flex" },
              onClick: () => {
                setExpanded((x) => !x);
              },
            })}
          >
            <div>
              <span className="bold">
                {dia.name} {dia.number}
              </span>
              &nbsp; De&nbsp; {dia.mes}&nbsp; del&nbsp; {dia.Año}
            </div>
            <div
              className={`icon_box row vcenter ${isExpanded ? "active" : ""}`}
            >
              <Icon
                className="row center dropw fontSizeLg"
                icon={"ri:arrow-drop-down-line"}
              />
            </div>
          </div>

          <div className="pad20 w100" {...getCollapseProps()}>
            <div className="row w100 hcenter">
              <table className="w100" cellPadding={0} cellSpacing={0}>
                <tr className="bold">
                  <td className="column ">Categoría</td>
                  <td className="column ">Actividad</td>
                  <td className="column ">Horas</td>
                  <td className="column ">
                    <div className="row hcenter">Editar</div>
                  </td>
                  <td className="column ">
                    <div className="row hcenter">Borrar</div>
                  </td>
                </tr>

                {actividades.map((actividad) => (
                  <Actividad
                    actividad={actividad}
                    actividades={actividades}
                    setActividades={setActividades}
                    inputRef={inputRef}
                    setInputFocus={setInputFocus}
                    categoriasList={categoriasList} 
                    categorias={categorias} 
                    setCategorias={setCategorias}
                  />
                ))}

                <Actividad
                  actividad={{
                    categoria: "",
                    actividad: "",
                    n_horas: 0,
                    edit: true,
                    isAdd: true,
                  }}
                  id={dia.day}
                  actividades={actividades}
                  setActividades={setActividades}
                  inputRef={inputRef}
                  setInputFocus={setInputFocus}
                  categoriasList={categoriasList} 
                  categorias={categorias} 
                  setCategorias={setCategorias}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
