import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { Zoom } from "react-reveal";
function genGrafica(calificacion) {
  return {
    series: [
      {
        name: "Resultado",
        data: [
          calificacion.parYCom,
          calificacion.colaboracion,
          calificacion.inovacion,
          calificacion.comEfec,
          calificacion.liderazgo,
          calificacion.adaptacionAlCambio,
          calificacion.cerYest,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "radar",
        locales: [
          {
            name: "es",
            options: {
              download: "Descargar",
              downloadPNG: "Descargar PNG",
              downloadJPEG: "Descargar JPEG",
              downloadPDF: "Descargar PDF",
              downloadSVG: "Descargar SVG",
              export: "Exportar",
              print: "Imprimir",
            },
          },
        ],
        defaultLocale: "es",
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: "#e9e9e9",
            fill: {
              colors: ["#f8f8f8", "#fff"],
            },
          },
        },
      },
      title: {
        text: "Estadisticas del usuario",
      },
      colors: ["#171dc1"],
      markers: {
        size: 4,
        colors: ["#fff"],
        strokeColor: "#171dc1",
        strokeWidth: 2,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        categories: [
          "Participación y compromiso",
          "Colaboración",
          "Innovación",
          "Comunicación efectiva",
          "Liderazgo",
          "Adaptación al cambio",
          "Certificaciones y estudios",
        ],
        labels: {
          style: {
            colors: ["#000", "#000", "#000", "#000", "#000", "#000", "#000"], // Define los colores de las categorías aquí
          },
        },
      },
    },
    yaxis: {
      tickAmount: 7,
      labels: {
        formatter: function (val, i) {
          if (i % 2 === 0) {
            return val;
          } else {
            return "";
          }
        },
      },
    },
  };
}

function genMultipleGrafica(usuariosSeleccionados) {
  function getColorArray(num) {
    var result = [];
    for (var i = 0; i < num; i += 1) {
      var letters = "0123456789ABCDEF".split("");
      var color = "#";
      for (var j = 0; j < 6; j += 1) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      result.push(color);
    }
    return result;
  }
  var series = usuariosSeleccionados.map((usuario) => {
    return {
      name: usuario.nombre,
      data: [
        usuario.calificacion.parYCom,
        usuario.calificacion.colaboracion,
        usuario.calificacion.inovacion,
        usuario.calificacion.comEfec,
        usuario.calificacion.liderazgo,
        usuario.calificacion.adaptacionAlCambio,
        usuario.calificacion.cerYest,
      ],
    };
  });
  console.log(series);
  return {
    series: series,
    options: {
      chart: {
        height: 350,
        type: "radar",
        locales: [
          {
            name: "es",
            options: {
              download: "Descargar",
              downloadPNG: "Descargar PNG",
              downloadJPEG: "Descargar JPEG",
              downloadPDF: "Descargar PDF",
              downloadSVG: "Descargar SVG",
              export: "Exportar",
              print: "Imprimir",
            },
          },
        ],
        defaultLocale: "es",
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: "#e9e9e9",
            fill: {
              colors: ["#f8f8f8", "#fff"],
            },
          },
        },
      },
      title: {
        text: "Estadisticas del usuario",
      },
      colors: getColorArray(10),
      markers: {
        size: 4,
        colors: ["#fff"],
        strokeColor: "#171dc1",
        strokeWidth: 2,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        categories: [
          "Participación y compromiso",
          "Colaboración",
          "Innovación",
          "Comunicación efectiva",
          "Liderazgo",
          "Adaptación al cambio",
          "Certificaciones y estudios",
        ],
        labels: {
          style: {
            colors: ["#000", "#000", "#000", "#000", "#000", "#000", "#000"], // Define los colores de las categorías aquí
          },
        },
      },
    },
    yaxis: {
      tickAmount: 7,
      labels: {
        formatter: function (val, i) {
          if (i % 2 === 0) {
            return val;
          } else {
            return "";
          }
        },
      },
    },
  };
}
function Estadisticas({ userDetalle, usuariosSeleccionados, data, setData }) {
  const [editar, setEditar] = useState(false);
  const [graficaDeCalificacion2, setGraficaDeCalificacion2] = useState(
    genGrafica(userDetalle.calificacion)
  );
  const [evaluacionUnica, setEvaluacionUnica] = useState(
    userDetalle.calificacion
  );
  useEffect(() => {
    setEditar(false);
    if (usuariosSeleccionados.length > 1)
      setGraficaDeCalificacion2(genMultipleGrafica(usuariosSeleccionados));
    setEvaluacionUnica(userDetalle.calificacion);
  }, [userDetalle, usuariosSeleccionados]);

  useEffect(() => {
    console.log(graficaDeCalificacion2);
  }, [graficaDeCalificacion2]);

  useEffect(() => {
    if (usuariosSeleccionados.length > 1 && !editar)
      setGraficaDeCalificacion2(genMultipleGrafica(usuariosSeleccionados));
    else setGraficaDeCalificacion2(genGrafica(evaluacionUnica));
  }, [evaluacionUnica]);

  useEffect(() => {
    if (editar) setGraficaDeCalificacion2(genGrafica(evaluacionUnica));
    else {
      if (usuariosSeleccionados.length > 1)
        setGraficaDeCalificacion2(genMultipleGrafica(usuariosSeleccionados));
      else setEvaluacionUnica(userDetalle.calificacion);
    }
  }, [editar]);

  function cambioDeValores(value, name) {
    var eu = { ...evaluacionUnica };
    if (value.length == 0) value = 0;
    eu[name] = !isNaN(value) ? parseInt(value) : evaluacionUnica[name];
    console.log(value, name, eu);
    setEvaluacionUnica(eu);
  }
  const EvaluacionTable = () => {
    return (
      <table className="w100 box_c" cellPadding={0} cellSpacing={0}>
        <thead className="table_header encab2">
          <tr className="table_header white bold hspace vcenter fontSizeSm">
            <td className="column w85">Criterio</td>
            <td className="column w15">Puntuacion</td>
          </tr>
        </thead>

        <tbody>
          <tr className="row_table hcenter">
            <td className="column hcenter titleStatus ">Identidad de equipo</td>
            <td className="column hcenter titleStatus"></td>
          </tr>
          <tr className="row_table ">
            <td className="column hcenter celdaConLinea">
              Participación y compromiso
            </td>
            <td className="column hcenter celdaConLinea">
              <input
                type="text"
                name="parYCom"
                className={`${editar ? "puntuacion-edit" : "puntuacion"}`}
                value={evaluacionUnica.parYCom}
                readOnly={!editar}
                onChange={(event) =>
                  cambioDeValores(event.target.value, "parYCom")
                }
              />
            </td>
          </tr>

          <tr className="row_table celdaConLinea">
            <td className="column hcenter celdaConLinea">Colaboracion</td>
            <td className="column hcenter celdaConLinea">
              <input
                type="text"
                name="colaboracion"
                className={`${editar ? "puntuacion-edit" : "puntuacion"}`}
                value={evaluacionUnica.colaboracion}
                readOnly={!editar}
                onChange={(event) =>
                  cambioDeValores(event.target.value, "colaboracion")
                }
              />
            </td>
          </tr>
          <tr className="row_table">
            <td className="column hcenter">Innovacion</td>
            <td className="column hcenter">
              <input
                type="number"
                name="inovacion"
                className={`${editar ? "puntuacion-edit" : "puntuacion"}`}
                value={evaluacionUnica.inovacion}
                readOnly={!editar}
                onChange={(event) =>
                  cambioDeValores(event.target.value, "inovacion")
                }
              />
            </td>
          </tr>
          <tr className="row_table hcenter">
            <td className="column hcenter titleStatus ">
              Gestión de desempeño
            </td>
            <td className="column hcenter titleStatus"></td>
          </tr>
          <tr className="row_table ">
            <td className="column hcenter celdaConLinea">
              Comunicación efectiva
            </td>
            <td className="column hcenter celdaConLinea">
              <input
                type="number"
                name="comEfec"
                className={`${editar ? "puntuacion-edit" : "puntuacion"}`}
                value={evaluacionUnica.comEfec}
                readOnly={!editar}
                onChange={(event) =>
                  cambioDeValores(event.target.value, "comEfec")
                }
              />
            </td>
          </tr>

          <tr className="row_table celdaConLinea">
            <td className="column hcenter celdaConLinea">Liderazgo</td>
            <td className="column hcenter celdaConLinea">
              <input
                type="number"
                name="liderazgo"
                className={`${editar ? "puntuacion-edit" : "puntuacion"}`}
                value={evaluacionUnica.liderazgo}
                readOnly={!editar}
                onChange={(event) =>
                  cambioDeValores(event.target.value, "liderazgo")
                }
              />
            </td>
          </tr>
          <tr className="row_table">
            <td className="column hcenter">Adaptación al cambio</td>
            <td className="column hcenter">
              <input
                type="number"
                name="adaptacionAlCambio"
                className={`${editar ? "puntuacion-edit" : "puntuacion"}`}
                value={evaluacionUnica.adaptacionAlCambio}
                readOnly={!editar}
                onChange={(event) =>
                  cambioDeValores(event.target.value, "adaptacionAlCambio")
                }
              />
            </td>
          </tr>
          <tr className="row_table hcenter">
            <td className="column hcenter titleStatus ">
              Conocimiento profesional
            </td>
            <td className="column hcenter titleStatus"></td>
          </tr>
          <tr className="row_table ">
            <td className="column hcenter celdaConLinea">
              Certificaciones y estudios
            </td>
            <td className="column hcenter celdaConLinea">
              <input
                type="number"
                name="cerYest"
                className={`${editar ? "puntuacion-edit" : "puntuacion"}`}
                value={evaluacionUnica.cerYest}
                readOnly={!editar}
                onChange={(event) =>
                  cambioDeValores(event.target.value, "cerYest")
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const EvaluacionButtons = () => {
    return (
      <div className="row w100 hright mtop15">
        <button
          className="btn_2 danger pointier tblack"
          onClick={() => setEditar(false)}
        >
          Cancelar
        </button>
        <button
          onClick={() => {
            Services.updateCalificacion(
              userDetalle,
              evaluacionUnica,
              (response) => {
                Services.getAllMiembros((response) => {
                  response.then((data) => {
                    setData(data);
                  });
                });
                toast.success("Calificación modificada");
                setEditar(false);
              }
            );
          }}
          className="btn_2 active pointier tblack"
        >
          Guardar
        </button>
      </div>
    );
  };
  return (
    <>
      <div className="row w100 hcenter">
        <div className="row w100 mtop25 hcenter">
          <div className="row mxw1000px wrap">
            <div className="row w100">
              <div className="col-12 md:col-8">
                <h1>Equipo Colaborativos de Conocimiento</h1>
              </div>
            </div>
            <div className="row w100">
              <div className="col-12 md:col-8">
                <p>
                  En la siguiente tabla se puede consultar la evaluación vigente
                  correspondiente al colaborador. Si el colaborador fue agregado
                  recientemente su puntuación será 0. Haga click en “modificar”
                  para agregar su puntaje.
                </p>
              </div>
            </div>
            <div className="row w100 hspace mtop25">
              <div className="">
                <div className="flex vcenter hspace">
                  <h5 className="hleft">{userDetalle.nombre}</h5>
                  <button
                    onClick={() => setEditar(true)}
                    className={`hcenter vcenter btn_2 tblack pointier ${
                      editar ? "active" : ""
                    }`}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                    <span className="tblack">Editar</span>
                  </button>
                </div>
                <br />
                <EvaluacionTable />
                <Zoom when={editar} duration={300}>
                  <EvaluacionButtons />
                </Zoom>
              </div>

              <div className="col-12 md:col-7 mtop25">
                <div id="chart" className="w100">
                  <ReactApexChart
                    options={graficaDeCalificacion2.options}
                    series={graficaDeCalificacion2.series}
                    type="radar"
                    className="grafico_Calificacion"
                    height={600}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Estadisticas;
