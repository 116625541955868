export default function Actions({
    currentOption,
    opciones,
    modales,
}) {
    return (
        <>
            <div className="row w100 hcenter">
                <div className="mxw1000px row vcenter mtop25 hspace menTam">
                    <div className="row vcenter">
                        {opciones.map((opcion, i) => (
                            <div
                                key={i}
                                className={`btn_2 row vcenter pointier relative ${currentOption.name === opcion.name ? "active" : ""
                                    }`}
                                onClick={() => {
                                    console.log(currentOption, opcion);
                                    opcion.onClick(opcion);
                                }}
                            >
                                {opcion.name}
                            </div>
                        ))}
                    </div>
                    <div className="row vrght">
                        {modales.map((opcion, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`btn_2 pointier row vcenter`}
                                    onClick={() => {
                                        opcion.onClick(opcion);
                                    }} >
                                    {opcion.print}
                                </div>

                            );
                        })}
                    </div>

                </div>
            </div>
        </>
    );
}
