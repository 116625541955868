import Modal from "./snippets/Modal";
import Form from "./snippets/Form";
import Title from "./snippets/Title";
import Inicio from "./snippets/Inicio";
import Navbar from "./snippets/Navbar";
import { useEffect, useState } from "react";

export default function Admin({ user, setUser, socket, Post, Get }) {
  const [menu_, setMenu] = useState(user ? user.rol.menu[0] : null);
  const [module, setModule] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [title, setTitle] = useState(["", ""]);
  const [pantalla, setPantalla] = useState("inicio");
  const [toggleModal, setToggleModal] = useState(false);
  const [modal, setModal] = useState(() => <div>hola</div>);
  const [aplicacion, setAplicaicon] = useState(null);
  const [titleModal, setTitleModal] = useState("Titulo");
  useEffect(() => {
    setToggle(false);
    setToggleModal(false);
    if (module != null) {
      setPantalla(module.type);
      setTitle([module.name, ""]);
      setAplicaicon(module.aplicaciones[0]);
    } else {
      setPantalla("inicio");
      if (user) setTitle(["Bienvenido ", `${user.nombre} ${user.apellido_p}`]);
    }
  }, [module]);

  useEffect(()=>{},[modal])
  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModal) {
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModal]);

  return (
    <div className="container">
      {modal && toggleModal ? (
        <Modal
          setToggleModal={setToggleModal}
          toggleModal={toggleModal}
          modal={modal}
          title={titleModal}
        />
      ) : (
        ""
      )}
      <Navbar
        menu_={menu_}
        user={user}
        module={module}
        setModule={setModule}
        toggle={toggle}
        setToggle={setToggle}
        setUser={setUser}
        socket={socket}
      />
      <div className="content w100">
        <Title title_bold={title[0]} title={title[1]} />
        {pantalla == "inicio" ? (
          <Inicio
            user={user}
            menu_={menu_}
            setModule={setModule}
            setMenu={setMenu}
          />
        ) : pantalla == "form" ? (
          <Form
            user={user}
            module={module}
            setToggleModal={setToggleModal}
            aplicacion={aplicacion}
            setAplicaicon={setAplicaicon}
            Post={Post}
            Get={Get}
            titleModal={titleModal}
            setTitleModal={setTitleModal}
            modal={modal}
            setModal={setModal}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
