
export default function Bienvenido({ user }) {
    return (
        <div className="row w100 hcenter">
        <div className="mxw1000px row vcenter hspace mtop25">
          <h2 className="fontSizeLg lighter ">
            Bienvenido{" "}
            <span className="bold">
              {user.nombre} {user.apellido_p}
            </span>
          </h2>
        </div>
      </div>
    )
}