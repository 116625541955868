import { Post, Get } from "../../App";
import { ToastContainer, toast } from "react-toastify";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Checkbox } from 'primereact/checkbox';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { toUTCDate } from "../Utils";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
/**
 * ModalForm Component
 *
 * @component
 * @param {Object} modal - Modal configuration object.
 * @param {string} modal.name - Name of the modal.
 * @param {string} modal.path - Path related to the modal.
 * @param {function} modal.onClick - Function to handle the modal click event.
 * @param {string} modal.print - Text to display in the modal.
 * @param {boolean} modal.add - Flag indicating if it's an "Add" operation.
 * @param {boolean} modal.isModal - Flag indicating if it's a modal.
 * @param {React.Component} modal.component - Custom component for the modal content.
 * @param {Object} modal.formData - Form data for the modal.
 * @param {Object} modal.formData.body - Body of the form data.
 * @param {string} modal.formData.endpoint - Endpoint for form submission.
 * @param {Object[]} modal.formData.fields - Array of form fields.
 * @param {string} modal.formData.fields[].type - Type of the form field (e.g., 'textField', 'multiSelect').
 * @param {string} modal.formData.fields[].name - Name of the form field.
 * @param {string} modal.formData.fields[].text - Text label for the form field.
 * @param {any} modal.formData.fields[].value - Value of the form field.
 * @param {string} modal.formData.fields[].getValue - Function to get the value of the form field.
 * @param {string} modal.tipo - Type of the modal (e.g., 'tabla').
 * @param {string} modal.seccion - Section to which the modal belongs.
 *
 * @example
 * const modalConfig = {
 *   name: "Equipos",
 *   path: "Equipos",
 *   onClick: (opcion) => {
 *     setModalesAbiertos([...modalesAbiertos, opcion.name]);
 *   },
 *   print: "Agregar Equipo",
 *   add: false,
 *   isModal: true,
 *   component: CustomModalComponent,
 *   formData: {
 *     body: {
 *       clave: '',
 *       nombre: '',
 *       usuarios: []
 *     },
 *     endpoint: '/api/equipos',
 *     fields: [
 *       { type: 'textField', name: 'clave', text: 'Clave', value: '', getValue: '' },
 *       { type: 'textField', name: 'nombre', text: 'Nombre', value: '', getValue: '' },
 *       { type: 'multiSelect', name: 'usuarios', text: 'Usuarios', value: [], getValue: '/path' }
 *     ],
 *   },
 *   tipo: "tabla",
 *   seccion: "1",
 * };
 *
 * <ModalForm modal={modalConfig} />
 */

import { useState } from "react";
import { useEffect } from "react";
function RenderField({ field, body, setBody, dinamicValues }) {
  const [value, setValue] = useState(
    dinamicValues[field.name] ? dinamicValues[field.name] : field.value
  );
  const [file, setFile] = useState(
  );
  const [listValues, setListValues] = useState(field.options ? field.options : []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(dinamicValues);
    dinamicValues[field.name]
      ? setValue(dinamicValues[field.name])
      : console.log();
  }, [dinamicValues]);
  useEffect(() => { }, [loading]);
  useEffect(() => { }, [listValues]);
  useEffect(() => {
    if (field.type !== "submit") {
      let bodyCP = body;
      bodyCP[field.name] = value;
      console.log(bodyCP);
      setBody(bodyCP);
    }
  }, [value]);
  useEffect(() => {
    if (field.getByEndPont && !loading && !field.depends) {
      var params = {
        "public_key":
          "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",

        "private_key":
          "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
      }
      setLoading(true);
      Post(
        field.method,
        (response) => {
          console.log(response);
          if (field.type === "multiSelect" || field.type === "select")
            setListValues(response.data);
          else setValue(response.data);
          setLoading(false);
        },
        (error) => { },
        true,
        "Cargando datos",
        params
      );
    }
  }, []);

  switch (field.type) {
    case "textField":
      return (
        <div className="m_10px">
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <InputText
            value={value}
            className="row w100 p25 hcenter"
            onChange={(event) => {
              field.onChange
                ? field.onChange(event.target.value)
                : setValue(event.target.value);
            }}
            placeholder={field.text}
            name={field.name}
            type="text"
            style={{ alignItems: "center", alignContent: "center" }}
          />
          <br />
        </div>
      );
    case "switchField":
      return (
        <div className="m_10px row hcenter wrap">
          <h3 className="row w100 hcenter ">{field.text}</h3>
          <InputSwitch
            checked={value}
            className="   hcenter"
            onChange={(event) => {
              field.onChange
                ? field.onChange(event.target.value)
                : setValue(event.value);
            }}
            placeholder={field.text}
            name={field.name}
            style={{ alignItems: "center", alignContent: "center" }}
          />
          <br />
        </div>
      );
    case "textArea":
      return (
        <>
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <InputTextarea placeholder={field.text}
            name={field.name} style={{ alignItems: "center", alignContent: "center" }} className="row w100 p25 hcenter mtop15" autoResize value={value} onChange={(e) => setValue(e.target.value)} rows={5} cols={30} />

          <br />
        </>
      );
    case "file":
      return (
        <>
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <input type="file" name={field.name} id={field.name} placeholder={field.text} style={{ alignItems: "center", alignContent: "center", maxWidth:200, border:'1px solid #ced4da', padding:5, borderRadius:4 }} className="row w100 p25 hcenter mtop15" value={file} onChange={(e) => {
            const file2 = e.target.files[0];
            console.log(file2);
            var reader = new FileReader();
            reader.readAsDataURL(file2);
           
              if (field.type !== "submit") {
                let bodyCP = body;
                bodyCP['content_type'] = file2.type;
                bodyCP['filename'] = file2.name;
                console.log(bodyCP);
                setBody(bodyCP);
              }
        
            reader.onload = function () {
              setValue(reader.result.split(',')[1]);
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };

          }} />

          <br />
        </>
      );
    case "numberField":
      return (
        <div className="m_10px">
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <InputNumber
            value={value}
            className="row w100 p25 hcenter "
            onChange={(event) => {
              field.onChange
                ? field.onChange(event.value)
                : setValue(event.value);
            }}
            placeholder={field.text}
            showButtons={field?.showButtons}
            buttonLayout={field?.buttonLayout}
            step={field?.step}
            name={field.name}
            min={0}
            currency={field.currency}
            mode={field.mode}
            locale="en-US"
            style={{ alignItems: "center", alignContent: "center" }}
          />
          <br />
        </div>
      );
    case "checkbox":
      return (
        <div className="m_10px">
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <Checkbox
            checked={value}
            className="row w100 p25 hcenter"
            onChange={(event) => {
              if (field.onChange) {
                field.onChange(event.checked)
                setValue(event.checked);
              }
              else
                setValue(event.checked);
            }}
            name={field.name}
            type="text"
            style={{ alignItems: "center", alignContent: "center" }}
          />
          <br />
        </div>
      );
    case "dateField":
      return (
        <div className="m_10px">
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <Calendar
            showIcon
            placeholder={field.text}
            value={field.selectionMode === "range" ? value : toUTCDate(value)}
            selectionMode={field.selectionMode ? field.selectionMode : "single"}
            readOnlyInput
            dateFormat="dd/mm/yy"
            className="row w100 p25 hcenter"
            onChange={(event) => {
              console.log(event);
              field.onChange
                ? field.onChange(event.target.value)
                : setValue(event.value);
            }}
            name={field.name}
          />
          <br />
        </div>
      );
    case "multiSelect":
      return (
        <div className="m_10px" style={{ maxWidth: 200 }}>
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <MultiSelect
            value={value}
            options={listValues}
            onChange={(event) => {
              field.onChange
                ? field.onChange(event.value)
                : setValue(event.value);
            }}
            placeholder={loading ? "Cargando" : `Selecciona un valor`}
            emptyFilterMessage={`Dato no encontrado`}
            optionLabel="name"
            optionValue="code"
            filter
            className="multiselect-custom row w100 hcenter"
            display="chip"
          />
        </div>
      );
    case "select":
      return (
        <div className="m_10px">
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          <Dropdown
            value={value}
            options={listValues}
            onChange={(event) => {
              field.onChange ? field.onChange(event.value) :
                setValue(event.value);
            }}
            placeholder={loading ? "Cargando" : `Selecciona un valor`}
            emptyFilterMessage={`Dato no encontrado`}
            optionLabel="name"
            optionValue="code"
            filter
            className="multiselect-custom row w100 hcenter"
            display="chip"
          />
        </div>
      );
    case "button":
      return (
        <div
          className="row w100 hcenter mtop10"
          style={{
            paddingInlineStart: "10%",
            paddingInlineEnd: "10%",
          }}
        >
          <Button label={field.text} icon="pi pi-check" raised onClick={() => {
            field.onClick(body);
          }} />
        </div>
      );
    case "custom":
      return (
        <>
          <h3 className="row w100 hcenter mb10">{field.text}</h3>
          {field.component(value, setValue)}
          <br />
        </>
      )
    default:
      break;
  }
}
export default function ModalForm({ modal, dinamicValues }) {
  const [body, setBody] = useState(modal.formData.body);
  useEffect(() => {
    console.log(body);
  }, [body]);

  return (
    <div className="row wrap w100 hcenter">
      <div
        className="row w100 hcenter wrap mtop15"
        style={{
          alignItems: "center",
          alignContent: "center",
          paddingInline: "15%",
        }}
      >
        <div className="row w100 wrap hcenter">
          {modal.formData.fields.map((field) => {
            if (field.visibleWhen ? dinamicValues[field.visibleWhen] : true)
              return (
                <RenderField
                  field={field}
                  setBody={setBody}
                  body={body}
                  dinamicValues={dinamicValues}
                />
              );
          })}
        </div>
      </div>
    </div>
  );
}
