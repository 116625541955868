import { Icon } from "@iconify/react";
import Zoom from "react-reveal/Zoom";
import { baseURL } from "../../App";
import { useState } from "react";
import AliceCarousel from "react-alice-carousel";
export default function Menu({ menu_, user, setMenu, setModule }) {

  const items = user.rol.menu.map((_menu) => (
    <Zoom>
      <div key={_menu.name} className="row hcenter w100 vcenter">
        <div
          className={`option pointier ${menu_ == _menu ? "active" : ""}`}
          onClick={() => setMenu(_menu)}
        >
          <div className="row h100 w100 hcenter vcenter wrap t_center">
            <div className="row w100 hcenter">
            <img
              src={`${baseURL}${_menu.icon}`}
              alt="icon"
              className="white"
              style={{ width: 40 }}
            />
            </div>
            <span className="f_small">{_menu.name}</span>
          </div>
        </div>
      </div>
    </Zoom>
  ));
  const thumbItems = (items, [setThumbIndex]) => {
    return items.map((item, i) => (
      <div key={item.name} className="thumb" onClick={() => setThumbIndex(i)}>
        {item}
      </div>
    ));
  };

  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbs] = useState(thumbItems(items, [setThumbIndex]));

  const responsive = {
    0: { items: 4 },
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
  };


  return (
    <div className="row hcenter w100 vcenter">
      <div className="row mxw600px vcenter">
        <AliceCarousel
          activeIndex={thumbIndex}
          autoWidth={false}
          infinite={false}
          disableButtonsControls
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="default"
          onSlideChanged={syncThumbs}
        />
      </div>
    </div>
  );
}
