import "./../../../static/scss/input.scss";

export default function Actions({
  setSearch,
  search,
}) {
  return (
    <div className="row hcenter">
      <div className="row mxw1000px">
        <div className="row col_2">
          <input
            type="text"
            className="input field search mxw600px"
            name="search_row"
            id="search_row"
            placeholder="Buscar"
            value={search}
            style={{height:32}}
            onChange={(e) => { setSearch(e.target.value)}}
          />
        </div>
      </div>
    </div>
  );
}
