import { Icon } from "@iconify/react";
import { Zoom } from "react-reveal";
import { useState } from "react";
import { useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import Actions from "./Module/Actions";
import Tabbar from "./Module/Tabbar";
import Table from "./Module/Table";
import Filter from "./Module/Filter";
import { Post,Get } from "../../App";
function pass(){}
export default function Form({
  user,
  module,
  setToggleModal,
  aplicacion,
  setAplicaicon,
  Get,
  setTitleModal,
  setModal,
}) {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsSearch, setRowsSearch] = useState([]);
  const [rowsF, setRowsF] = useState([]);
  const [search, setSearch] = useState("");
  const [filterss, setFilters] = useState({});
  function checkEmpty(fff){
    var empty = true
    for(var i in Object.keys(fff)){
        var filtroA = Object.keys(fff)[i]
        if(fff[filtroA].length>0)
            empty=false
    }
    return empty
}
  function filter() {
    var arraySe = [];
    var rowm = rows
    if(!checkEmpty(filterss))rowm=rowsF
    for (let row in rowm) {
      for (let value in Object.values(rowm[row])) {
        if (value > 0  && value<Object.values(rowm[row]).length-1) {
          if (
            Object.values(rowm[row])
              [value].toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                search
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              )
          )
            if (!arraySe.includes(rowm[row])) arraySe.push(rowm[row]);
        }
      }
    }

    setRowsSearch(arraySe);
  }

  useEffect(() => {
    setLoad(true);
    setSearch("");
    setFilters({})
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    Post(
      `${aplicacion.Path}/get/`,
      (response) => {
        setData(response.data);
        pass(response);
        setLoad(false);
      },
      (error) => {
        pass(error);
        setLoad(false);
      },
      false,
      null,
      params
    );
  }, [aplicacion]);

  useEffect(() => {
    if (data) setRows(data.data);
    else setRows([]);
  }, [data]);

  useEffect(() => {
    if (search.length > 0) filter();
    else if(!checkEmpty(filterss)) setRowsSearch(rowsF)
    else setRowsSearch(rows);
  }, [search]);

  useEffect(() => {
  }, [rowsSearch]);

  useEffect(() => {
    if (search.length > 0) filter();
    else setRowsSearch(rows);
  }, [rows]);

  if (module && aplicacion)
    return (
      <div className="box">
        <Actions
          setSearch={setSearch}
          search={search}
        />
        <Tabbar
          module={module}
          aplicacion={aplicacion}
          setAplicaicon={setAplicaicon}
        />
        {data && rows[0] ? (
          data.filter ? (
            <div className="row hcenter">
              <div className="mxw1000px mtop25 row hleft">
              <Filter
              setFilters={setFilters}
              rows={rows}
              filters={data.filter}
              rowsSearch={rowsSearch}
              setRowsSearch={setRowsSearch}
              filterss={filterss}
              search={search}
              setRowsF={setRowsF}
            />
              </div>
              
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <Table
          data={data}
          rows={rowsSearch}
          load={load}
          setToggleModal={setToggleModal}
          setTitleModal={setTitleModal}
          setModal={setModal}
          aplicacion={aplicacion}
          Post={Post}
          Get={Get}
          setData={setData}
        />
      </div>
    );
  else return <div></div>;
}
