import Logotipo from "./../static/images/Logotipo.svg";
import { Icon } from "@iconify/react";
import useCollapse from "react-collapsed";
import { useState, useEffect } from "react";
import Bounce from "react-reveal/Bounce";
import Filter from "./snippets/Module/Filter";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ThreeCircles } from "react-loader-spinner";
import { Zoom } from "react-reveal";
import ModalReporte from "./snippets/MoldaReporte";
import ModalHoras from "./snippets/MoldaHoras";

export default function Aprobador({ setUser, user, Post }) {
  const [isExpanded, setExpanded] = useState(false);
  const [showText, setShowText] = useState(false);
  const [comment,setComment]=useState("")
  const [loader, setLoader] = useState(true);
  const [notificaciones, setNotificaciones] = useState(user.notificaciones);
  const [data, setData] = useState(null);
  const [rows, setRows] = useState([]);
  const [uuid, setUUID] = useState(null);
  const [rowsSearch, setRowsSearch] = useState([]);
  const [update, setUpdate] = useState({});
  const [rowsF, setRowsF] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filterss, setFilters] = useState({});
  const [aplication, setAplicaicon] = useState("hoja_actividades");
  const [method, setMethod] = useState("get/");

  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModal) {
      setUUID(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModal]);

  useEffect(() => {
    setLoader(true);
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    Post(
      `${aplication}/${method}`,
      (response) => {
        setLoader(false);
       
        setRows(response.data.data);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
      
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [aplication, method]);

  useEffect(() => {
    setLoader(true);
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    Post(
      `${aplication}/get/`,
      (response) => {
        setLoader(false);
        setToggleModal(false);
   
        setRows(response.data.data);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
     
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [update]);

  useEffect(() => {
    if (data) setRows(data.data);
    else setRows([]);
  }, [data]);

  useEffect(() => {}, [rowsSearch]);

  useEffect(() => {
    
  }, [notificaciones]);

  useEffect(() => {

  });

  useEffect(() => {
    setRowsSearch(rows);
  }, [rows]);

  return (
    <div className="container">
      {uuid && (
        <ModalReporte 
          setToggleModal={setToggleModal}
          uuid={uuid}
          Post={Post}
          setUpdate={setUpdate}
          user={user}
        />
      )}
      <div className="w100">
        <div className="row w100 pad10 bar hcenter vcenter  ">
          <div className="mxw1000px row vcenter hspace">
            <img
              src={Logotipo}
              alt="inicio"
              width={40}
              className={"pointier"}
            />
            <Icon
              icon={"material-symbols:logout-rounded"}
              style={{ fontSize: 40, color: "#fff" }}
              className={"pointier"}
              onClick={() => setUser(null)}
            />
          </div>
        </div>
        <div className="row pad20 wrap">
          <div className="row w100 hcenter">
            <div className="mxw1000px row vcenter hspace mtop25">
              <h2 className="fontSizeLg lighter ">
                Bienvenido{" "}
                <span className="bold">
                  {user.nombre} {user.apellido_p}
                </span>
              </h2>
            </div>
          </div>
          <div className="row w100 hcenter mtop25">
            <div className="mxw1000px row vcenter hspace">
              <div className="box_c w100">
                <div
                  className="box_header w100 white bold hspace vcenter pointier"
                  {...getToggleProps({
                    style: { display: "flex" },
                    onClick: () => {
                      setExpanded((x) => !x);
                    },
                  })}
                >
                  <p className="fontSizeSm">Notificaciones</p>
                  <div className={`icon_box ${isExpanded ? "active" : ""}`}>
                    <Icon
                      className="row center dropw fontSizeLg"
                      icon={"ri:arrow-drop-down-line"}
                    />
                  </div>
                </div>

                <div className="padlr20" {...getCollapseProps()}>
                  {notificaciones.length > 0 ? (
                    notificaciones.map((notificacion) => (
                      <div className="row w100 notification hspace">
                        <p>
                          {notificacion.created.substring(0, 10)} -{" "}
                          {notificacion.message}{" "}
                          {notificacion.type == "firmar" && (
                            <span
                              className="bold pointier"
                              style={{
                                color: "#1461FF",
                                textDecoration: "underline",
                              }}
                              onClick={() => {
                                setMethod("get_firmar/");
                                setToggleModal(true);setUUID(notificacion.uuid_redirect)
                               
                              }}
                            >
                              Revisalas ahora
                            </span>
                          )}
                        </p>
                        <span
                          className="row vcenter pointier"
                          onClick={() => {
                            for (let i in notificaciones) {
                              if (notificacion == notificaciones[i]) {
                                let cpArray = notificaciones.filter(
                                  (notificacionO) =>
                                    notificacionO != notificaciones[i]
                                );
                                setNotificaciones(cpArray);
                              }
                            }
                            var params = new FormData();
                            params.append(
                              "public_key",
                              "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                            );
                            params.append(
                              "private_key",
                              "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                            );
                            params.append("uuid", notificacion.uuid);
                            Post(
                              `notification/read/`,
                              (response) => {},
                              (error) => {},
                              false,
                              "Obteniendo datos",
                              params
                            );
                          }}
                        >
                          Archivar
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="row w100 notification hspace">
                      <p>No tienes notificaciones</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row w100 hcenter mtop15">
            <div className="mxw1000px row vcenter mtop15">
              <h3 className="fontSizeMd ">
                {method == "get/" ? "Historial General" : "Hojas por Aprobar"}
              </h3>
              <div className="relative h100">
                <div className="absolute">
                  <div className="row vcenter">
                    <Icon
                      icon={"material-symbols:help"}
                      className={`fontSizeSmmr pointier ${
                        showText ? "c_active" : ""
                      }`}
                      style={{ marginLeft: 5, top: 0 }}
                      onClick={() => setShowText(!showText)}
                    />

                    <Bounce when={showText}>
                      <div className="info_box fontSizeSmr">
                        Esta lista muestra los reportes de actividades por
                        proyecto en curso que tiene con nosotros. La aprobación
                        de estos nos ayuda grandemente a monitorear la
                        eficiencia de nuestros consultores y sus tiempos
                        efectivos.
                      </div>
                    </Bounce>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row w100 hcenter">
            <div className="mxw1000px row vcenter mtop15">
              {method == "get/" ? (
                <p className="fontSizeSm mxw800px">
                  En la siguiente lista encontrarás el historial general de{" "}
                  <span className="bold">hojas de aprobación</span>{" "}
                  emitidas por los colaboradores que tienes asignados. Haz click
                  en “<span className="bold">Firmar Hojas</span>” para acceder a
                  la lista de hojas que requieren de aprobación.
                </p>
              ) : (
                <p className="fontSizeSm mxw800px">
                  En la siguiente lista encontrarás las{" "}
                  <span className="bold">hojas por aprobar</span> de los
                  colaboradores que tienes asignados.
                </p>
              )}
            </div>
          </div>
          <div className="row w100 hcenter mtop15">
            <div className="mxw1000px row vcenter mtop25">
              {data != null && data.filter && (
                <Filter
                  setFilters={setFilters}
                  rows={rows}
                  filters={data.filter}
                  rowsSearch={rowsSearch}
                  setRowsSearch={setRowsSearch}
                  filterss={filterss}
                  search={search}
                  setRowsF={setRowsF}
                />
              )}

              <div
                className={`btn_2 row vcenter pointier relative bold ${
                  method == "get/" ? "active" : ""
                }`}
                onClick={() => setMethod("get/")}
              >
                Historial General
              </div>

              <div
                className={`btn_2 row vcenter pointier relative bold ${
                  method == "get_firmar/" ? "active" : ""
                }`}
                onClick={() => setMethod("get_firmar/")}
              >
                Hojas por firmar
              </div>
            </div>
          </div>
          <div className="row w100 hcenter">
            <div className="mxw1000px mtop15">
              {loader ? (
                <div className="box_c w100">
                  <div className="table_header pad15 row fontSizeSmr">
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                  </div>
                  <div className="row w100 hcenter pad15 vcenter">
                    <ThreeCircles
                      height="43"
                      width="43"
                      color="#0061FF"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor=""
                      middleCircleColor=""
                    />
                  </div>
                </div>
              ) : (rows != null) & (rows.length > 0) ? (
                <table className="box_c w100 t_center" cellPadding={0} cellSpacing={0}>
                  <thead className="table_header pad15">
                    <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                      {Object.keys(rows[0]).map(
                        (key) =>
                          key != "uuid" &&
                          key != "options" &&
                          key != "ready" && (
                            <td className="column" key={key}>
                              {key}
                            </td>
                          )
                      )}
                      {method == "get_firmar/" ? (
                        <>
                          <td className="column">Reporte</td>
                          <td className="column">Comentario</td>
                          <td className="column"><div className="row hcenter">Aprobar</div></td>
                        </>
                      ) : (
                        <td className="column">Reporte</td>
                      )}
                    </tr>
                  </thead>
                  <Zoom cascade>
                    <tbody>
                      {rowsSearch.map((row) => (
                        <tr className="row_table">
                          {Object.keys(row).map(
                            (key) =>
                              key != "uuid" &&
                              key != "options" &&
                              key != "ready" &&
                              (key == "Estatus de Reporte" ? (
                                <td className={`column`}>
                                  <ProgressBar now={row[key]} />{" "}
                                </td>
                              ) : (

                                key=="Estatus de reporte"?
                                <td className={`column`}><span className={`${row[key].toLowerCase()} row hcenter`} style={{maxWidth:100, margin:0, margin:"auto"}} >{row[key]}</span> </td>:
                                <td className={`column`}>{row[key]} </td>
                              ))
                          )}
                          {method == "get/" ? (
                            <td className="column">
                             <div className="row hcenter">
                             <Icon
                                icon={"ic:baseline-remove-red-eye"}
                                className="fontSizeLg pointier hover_green"
                                onClick={()=>{setToggleModal(true);setUUID(row.uuid)}}
                              />
                             </div>
                            </td>
                          ) : (
                            <>
                              <td className="column">
                                <Icon
                                  icon={"ic:baseline-remove-red-eye"}
                                  className="fontSizeLg pointier hover_green"
                                  onClick={()=>{setToggleModal(true);setUUID(row.uuid)}}
                                />
                              </td>
                              <td className="column">
                                <input
                                  type="text"
                                  className="input_actividad"
                                  onChange={(e)=>{setComment(e.target.value)}}
                                />
                              </td>
                              <td
                                className="column row"
                                style={{ justifyContent: "space-evenly" }}
                              >
                                <Icon
                                  icon={"ant-design:dislike-twotone"}
                                  vFlip={true}
                                  className="fontSizeLg pointier hover_green"
                                  onClick={() => {
                                    var params = new FormData();
                                    params.append(
                                      "public_key",
                                      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                                    );
                                    params.append("uuid", row.uuid);
                                    params.append(
                                      "private_key",
                                      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                                    );
                                    Post(
                                      `${aplication}/accept/`,
                                      (response) => {
                                        setLoader(false);
                                      
                                        setRows(response.data.data);
                                        setData(response.data);
                                      },
                                      (error) => {
                                        setLoader(false);
                                        
                                      },
                                      true,
                                      "Enviando",
                                      params
                                    );
                                  }}
                                />
                                <Icon
                                  icon={"ant-design:dislike-twotone"}
                                  hFlip={true}
                                  className="fontSizeLg pointier hover_red"
                                  onClick={()=>{
                                    var params = new FormData();
                                    params.append(
                                      "public_key",
                                      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                                    );
                                    params.append("uuid", row.uuid);
                                    params.append("comentario", comment);
                                    params.append(
                                      "private_key",
                                      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                                    );
                                    Post(
                                      `${aplication}/update/`,
                                      (response) => {
                                        setLoader(false);
                                        
                                        setRows(response.data.data);
                                        setData(response.data);
                                      },
                                      (error) => {
                                        setLoader(false);
                                        
                                      },
                                      true,
                                      "Enviando",
                                      params
                                    );
                                  }}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Zoom>
                </table>
              ) : (
                <div className="table_header pad15 row vcenter hcenter white bold fontSizeSm">
                  Felicidades, no tienes hojas de actividades pendientes
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
