import "./../static/scss/login.scss";
import Logo from "./../static/images/Logo.png";
import logow from "./../static/images/logow.png";
import contraseña from "./../static/images/contraseña.png";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Fade } from "react-reveal";
import { ThreeCircles } from "react-loader-spinner";
function pass(){}
export default function Restart({ toast, Post, user, setUser }) {
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code')
  
  const [submit, setSubmit] = useState(false);
  const [redirect,setRedirect]= useState(false)
  const [toggleModal, setToggleModal] = useState(false);
  const [verified,setVerified] = useState(false)
  useEffect(() => {}, [username, password]);
  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModal) {
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
    if(toggleModal)setSubmit(false)
  }, [toggleModal]);

  function login(event) {
    event.preventDefault();
    if (password.length == 0 || username.length == 0)
      toast.error("Debes de llenar todos los campos", {
        toastId: "Debes de llenar todos los campos",
        theme: "dark",
      });
    else {
      const params = new URLSearchParams();
      params.append("password", username);
      params.append("passwordR", password);
      params.append("code",code)

      if(username!=password){
        toast.error("Las contraseñas no coinciden", {
          toastId: "Las contraseñas no coinciden",
          theme: "dark",
        });
        return
      }
      
      params.append(
        "public_key",
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
      );

      Post(
        "/restart",
        (response) => {
          pass(response);
          setRedirect(true)
        },
        (error) => {
          pass(error);
        },
        true,
        "Restableciendo contraseña",
        params
      );
    }
  }

  if (user) return <Navigate to="/" />;
  
  if (redirect) return <Navigate to="/login" />;

  if(!code) return <Navigate to="/login" />;

  if(!verified && code){
    const params = new URLSearchParams();
    params.append("code", code);
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );

    Post(
      "restart/check/",
      (response) => {
        pass(response);
        if(response.data.status)
          setVerified(true)
        else
          setRedirect(true)
      },
      (error) => {
        pass(error);
      },
      false,
      "Restableciendo contraseña",
      params
    );
  }

  if (!verified)
  return (
    <div className="h100vh row center hcenter vcenter">
      <ThreeCircles
        height="60"
        width="60"
        color="#0061FF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
    </div>
  );

  return (
    <>
      <div className="container bg_1 vcenter">
        <form className="mx537px" onSubmit={login}>
          <div className="row w100 hcenter">
            <img src={logow} alt="eks" width="212" />
          </div>
          <div className="row w100 hcenter">
            <Fade>
            <div className="form_login row hcenter wrap">
              <input
                type="text"
                className="input_login"
                placeholder="Nueva Contraseña"
                onChange={(event) => {
                  setusername(event.target.value);
                }}
              />
              <input
                type="text"
                className="input_login"
                placeholder="Repetir Contraseña"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <div className="row hcenter w100">
                <button className="btn_login row hcenter vcenter">
                  <h2>Restablecer</h2>
                </button>
              </div>
            </div>
            </Fade>
          </div>
        </form>
      </div>
    </>
  );
}
