import Logotipo from "./../static/images/Logotipo.svg";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import Filter from "./snippets/Module/Filter";
import { ThreeCircles } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import Actions from "./snippets/Module/Actions";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

import { InputSwitch } from "primereact/inputswitch";
import { Zoom } from "react-reveal";
import Actividades from "./../static/images/Actividades.svg";
import Asignaciones from "./../static/images/Asignaciones.svg";
import Empleadoss from "./../static/images/Empleadoss.svg";
import compu from "./../static/images/compu.svg";
import { ReactSVG } from "react-svg";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import Fade from "react-reveal/Fade";
import { Calendar } from "primereact/calendar";
import { Dialog } from 'primereact/dialog';
import { baseURL } from "../App";
import ModalReporte from "./snippets/MoldaReporte";
import ModalEdit from "./snippets/ModalEdit";
import ModalEditDispositivosCH from "./snippets/ModalEditDispositivosCH";
import ModalHistorialDisp from "./snippets/ModalHistorialDisp";
import useCollapse from "react-collapsed";
import ModalDocumentoCH from "./snippets/ModalDocumentoCH";

import { Button } from 'primereact/button';
        

function pass() {}
export default function CapitalH2({ setUser, user, Post }) {
  // Menus de la barra superior junto con cada una de sus secciones
  const menus = [
    {
      menu: "Empleados y Clientes", //Sirve para saber si el boton esta activo
      name: "Gestión de Empleados y Aprobadores", //Nombre que aparecera en la pagina del meni
      description: // descripcion que aparecera en la pagina del menu
        "En la siguiente lista encontrarás los colaboradores y clientes registrados en la plataforma según la instancia donde fueron registrados. Para mostrar la lista del catálogo de clientes, da click en Filtro > Cliente.",
      opciones: [ // Botones que apareceran debajo del menu
        {
          name: "Catálogo de Usuarios", //nombre que aparecera en el boton
          path: "users",  //nombre del endpoint al que debe de ir para traer datos
          print: "Usuario", //nombre que aparecera ene el boton de crear 
          add: true, // boolean para saber si en esa ventana tendra la opcion de agregar nuevos datos
        },
        {
          name: "Catálogo de Empleados",
          path: "empleado",
          print: "Empleado",
          add: true,
        },
        {
          name: "Catálogo de Clientes",
          path: "cliente",
          print: "Cliente",
          add: true,
        },
      ],
    },
    {
      menu: "Hoja de Actividades",
      name: "Hojas de Actividades",
      description:
        "En la siguiente lista encontrarás el historial general de hojas de aprobación emitidas por los colaboradores . Haz click en reporte para imprimir las hojas de actividades.",
      opciones: [
        {
          name: "Hoja de Actividades",
          path: "hoja_actividades",
          add: false,
          print: "",
        },
      ],
    },
    {
      menu: "Gestión de Asignaciones",
      name: "Gestión de Asignaciones",
      description:
        "En la siguiente lista encontrarás las entradas a las asignaciones por cliente y entidad. Haz click en “Consultar” para modificar los recursos asignados o los datos de alta de la asignación.",
      opciones: [
        {
          name: "Asignaciones",
          path: "asignacion",
          print: "Asignación",
          add: true,
        },
      ],
    },
    {
      menu: "Registro Dispositivos",
      name: "Registro de dispositivos",
      description:
        "En la siguiente lista encontrarás el inventario de dispositivos disponibles.",
      opciones: [
        {
          name: "Catálogo de computadoras",
          path: "compus-rh",
          path_historial:"historial-computadoras",
          print: "Computadora",
          add: false,
          // Mauricio
          tipo: "tabla",
          dato: null
          //
        },
        {
          name: "Catálogo de celulares",
          path: "celulares-rh",
          path_historial:"historial-celular",
          print: "Celular",
          add: false,
          // Mauricio
          tipo: "tabla",
          dato: null
          //
        },
      ],
    }
  ];
  const [app, setApp] = useState(menus[0]); //Es el menu completo
  const [entidad, setEntidad] = useState(menus[0].opciones[0]); //Es uno de los botones que aparecen en cada menu
  const [data, setData] = useState(null); // Es la informacion que manda el endpoint (toda la respuesta)
  const [rows, setRows] = useState([]); // Es lo mismo que response.data.data (los datos)
  const [form, setForm] = useState(false); //Es un booleano que se usa para saber si se muestra o no el formulario de creacion
  const [toggleModal, setToggleModal] = useState(false); //Es un booleano para la ventana de reportes
  const [toggleModalR, setToggleModalR] = useState(false); //Es un booleano para la ventana de edicion de un registro 
  // Mauricio
  const [toggleModalCompus, setToggleModalCompus] = useState(false);
  const [toggleModalHistorialCompus, setToggleModalHistorialCompus] = useState(false); 
  const [dataAsignarCompus, setDataAsignarCompus] = useState({"Marca":"marca","Modelo":"modelo","numero_serie":"numeroDeSerie","asignado_a":"asignado", "opciones":[]});
  const [selectedAsignado, setSelectedAsignado] = useState(null);
  //
  const [rowsSearch, setRowsSearch] = useState([]); // Es la informacion pero filtrada por la barra search
  const [uuidReport, setUUIDReport] = useState(null); // Es el valor del uuid del registro 
  const [uuidForm, setUUIDForm] = useState(null); // Es el valor del uuid del registro 
  //Mauricio
  const [uuidFormRH, setUUIDFormRH] = useState(null);
  const [uuidHistorialCompus, setUUIDHistorialCompus] = useState(null);

  const [toggleModalDocumento, setToggleModalDocumento] = useState(false);
  const [uuidDocumento, setUUIDDocumento] = useState(null);

  const [isExpanded, setExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });
  const [notificaciones, setNotificaciones] = useState(user.notificaciones);
  //
  const [filterss, setFilters] = useState({}); //No se, verificar en el modalEdit
  const [search, setSearch] = useState(""); //Lo que se ingresa en la barra de search
  const [loader, setLoader] = useState(true);
  const [menu, setMenu] = useState(menus[0].menu); // El nombre del menu principal
  const [rowsF, setRowsF] = useState([]); //No se, hay que verlo en el modalEdit
  const [aplication, setAplicaicon] = useState(menus[0].opciones[0].path); //Es el path

  //Checar si esta vacio los filtros
  function checkEmpty(fff){
    var empty = true
    for(var i in Object.keys(fff)){
        var filtroA = Object.keys(fff)[i]
        if(fff[filtroA].length>0)
            empty=false
    }
    return empty
}

// Filtrar la tabla por lo que esta en la barra search
function filter() {
  var arraySe = [];
  var rowm = rows
  if(!checkEmpty(filterss))rowm=rowsF
  for (let row in rowm) {
    for (let value in Object.values(rowm[row])) {
      if (value > 0  && value<Object.values(rowm[row]).length-1) {
        if( Object.values(rowm[row])[value] != null){
          if (
            Object.values(rowm[row])
              [value].toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                search
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              )
          )
            if (!arraySe.includes(rowm[row])) arraySe.push(rowm[row]);
        }
      }
    }
  }

  setRowsSearch(arraySe);
}

  function Input({ field, modelo, setModelo }) { //Es un componente al que ingresan: No se
    const [value, setValue] = useState(modelo ? modelo[field.name] : "");
    var v = [];
    if (field.type == "select" && !field.multiple) {
      for (let i in field.values) {
        if (field.values[i].value == value) {
          v = { name: field.values[i].label, code: field.values[i].value };
        }
      }
    }
    const [checked, setChecked] = useState(false);
    const [select, setSelect] = useState(v);
    useEffect(() => {
      var modelocp = { ...modelo };
      pass(value);
      if (value || value == "") {
        if (field.type == "select" && value == "") modelocp[field.name] = [];
        else modelocp[field.name] = value;
        setModelo(modelocp);
      }
    }, [value]);

   

    

    useEffect(() => {
      var modelocp = { ...modelo };
      if (field.type == "checkbox") {
        modelocp[field.name] = checked;
        setModelo(modelocp);
      }
    }, [checked]);

    useEffect(() => {
      pass(field.name);
    }, [select]);

    if (field.type == "text")
      return (
        <span className="p-float-label" key={field.name}>
          <InputText
            id={field.name}
            value={modelo[field.name]}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "checkbox")
      return (
        <span
          className="p-float-label"
          key={field.name}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <InputSwitch
            checked={checked}
            onChange={(e) => setChecked(e.value)}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "number")
      return (
        <span className="p-float-label" key={field.name}>
          <InputNumber
            id={field.name}
            useGrouping={false}
            value={modelo[field.name]}
            onChange={(e) => {
              setValue(e.originalEvent.target.value);
            }}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "email")
      return (
        <span className="p-float-label" key={field.name}>
          <InputText
            id={field.name}
            value={modelo[field.name]}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "file")
      return (
        <span className="p-float-label" key={field.name}>
          <FileUpload
            mode="basic"
            name={field.name}
            accept={field.accept}
            maxFileSize={1000000}
            chooseLabel={`Subir ${field.name}`}
          />
        </span>
      );

    if (field.type == "select") {
      var datos = [];
      for (let i in field.values) {
        datos.push({
          name: field.values[i].label,
          code: field.values[i].value,
        });
      }

      if (!field.multiple)
        return (
          <span className="p-float-label" key={field.name}>
            <Dropdown
              optionLabel="name"
              placeholder={field.print_name}
              className="w-full md:w-14rem"
              value={select}
              options={datos}
              onChange={(e) => {
                setValue(e.value.code);
                setSelect(e.value);
              }}
            />
            <label htmlFor={field.name}>{field.print_name}</label>
          </span>
        );

      return (
        <span className="p-float-label" key={field.name}>
          <MultiSelect
            value={select}
            onChange={(e) => {
              setSelect(e.value);
              pass(e.value);
              var datos = [];
              for (let i in e.value) {
                datos.push(e.value[i].code);
              }
              var modelocp = { ...modelo };
              modelocp[field.name] = datos;
              setModelo(modelocp);
            }}
            options={datos}
            optionLabel="name"
            display="chip"
            placeholder={`Selecciona ${field.print_name}`}
            className=""
            maxSelectedLabels={3}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );
    }

    if (field.type == "date")
      return (
        <span className="p-float-label" key={field.name}>
          <Calendar
            value={value}
            onChange={(e) => setValue(e.value)}
            dateFormat="yy-mm-dd"
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );
  }

  function AddRow({ // Es un componente al que ingresan: No se
    field,
    editO,
    dato,
    setDatos,
    datos,
    modelo,
    setModelo,
    modelO,
  }) {
    const [edit, setEdit] = useState(false);
    const [model, setModel] = useState(modelO);

    useEffect(() => {
      setModel(modelO);
    }, [edit]);

    useEffect(() => {
      pass(model);
    }, [model]);

    if (editO) {
      if (model)
        return (
          <tr className="row_table">
            {field.fields.map((fieldO) => {
              return (
                <td className="column">
                  <div className="row vcenter">
                    {fieldO == field.fields[0] && (
                      <Icon
                        icon={"material-symbols:add-circle"}
                        className="fontSizeMd  pointier c_color"
                        style={{ marginRight: 5 }}
                        onClick={() => {
                          if (model[fieldO.name].length > 0) {
                            setDatos([...modelo[field.id], model]);
                            var modelcp = {};

                            for (let i in field.fields) {
                              modelcp[field.fields[i].name] = "";
                            }
                            setModel(modelcp);
                          }
                        }}
                      />
                    )}

                    <input
                      className={`input_actividad w100 ${
                        fieldO == field.fields[0] ? "required" : ""
                      }`}
                      required
                      placeholder={fieldO.print_name}
                      id={"id"}
                      value={model[fieldO.name]}
                      type={fieldO.type}
                      onChange={(e) => {
                        pass(model);
                        var modelcp = { ...model };
                        modelcp[fieldO.name] = e.target.value;
                        setModel(modelcp);
                        pass(modelcp);
                      }}
                    />
                  </div>
                </td>
              );
            })}
            <td className="column"></td>
            <td className="column"></td>
          </tr>
        );
    }
    if (edit) {
      return (
        <tr className="row_table">
          {field.fields.map((fieldO) => {
            return (
              <td className="column">
                <input
                  className="input_actividad w100"
                  required
                  placeholder={fieldO.print_name}
                  id={"id"}
                  value={model[fieldO.name]}
                  type={fieldO.type}
                  onChange={(e) => {
                    var modelcp = { ...model };
                    modelcp[fieldO.name] = e.target.value;
                    setModel(modelcp);
                    pass(modelcp);
                  }}
                />
              </td>
            );
          })}
          <td className="column">
            <div className="row vcenter hcenter">
              <Icon
                icon={"material-symbols:edit-square-outline"}
                className="fontSizeMd hover_green pointier"
                onClick={() => {
                  setEdit(false);
                  let obj = model;
                  let actividadesCP = [...datos];
                  for (let i in actividadesCP) {
                    pass(actividadesCP[i]);
                    pass(dato);
                    if (dato == actividadesCP[i]) {
                      actividadesCP[i] = obj;
                      setDatos(actividadesCP);
                      pass(actividadesCP);
                      break;
                    }
                  }
                }}
              />
            </div>
          </td>
          <td className="column">
            <div className="row vcenter hcenter">
              <Icon
                icon={"bi:trash"}
                className="fontSizeMd hover_green pointier"
                onClick={() => {
                  let cpArray = modelo[field.id].filter(
                    (actividadO) => actividadO != dato
                  );
                  setDatos(cpArray);
                }}
              />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr className="row_table">
        {Object.values(dato).map((value) => (
          <td className="column">
            <div className="row vcenter">{value}</div>
          </td>
        ))}

        <td className="column">
          <div className="row vcenter hcenter">
            <Icon
              icon={"material-symbols:edit-square-outline"}
              className="fontSizeMd hover_green pointier"
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
        </td>
        <td className="column">
          <div className="row vcenter hcenter">
            <Icon
              icon={"bi:trash"}
              className="fontSizeMd hover_green pointier"
              onClick={() => {
                let cpArray = modelo[field.id].filter(
                  (actividadO) => actividadO != dato
                );
                setDatos(cpArray);
              }}
            />
          </div>
        </td>
      </tr>
    );
  }

  function Group({ data, i, model2, m, field }) { // Es un componente al que ingresan: No se
    const [modelo, setModelo] = useState({ ...model2 });
    const [datos, setDatos] = useState([]);
    
    const RowA = () => {
      var modelcp = {};
      for (let i in field.fields) {
        if (field.fields[i].type == "select")
          modelcp[field.fields[i].name] = [];
        else modelcp[field.fields[i].name] = "";
        pass(modelcp);
      }

      return (
        <AddRow
          field={field}
          editO={true}
          setDatos={setDatos}
          datos={modelo[field.id]}
          modelo={modelo}
          setModelo={setModelo}
          modelO={modelcp}
        />
      );
    };

    useEffect(() => {
      var cpmodelo = { ...modelo };
      cpmodelo[field.id] = datos;
      setModelo(cpmodelo);
    }, [datos]);

    useEffect(() => {
      m[0] = modelo;
    }, [modelo]);
    
    if (field)
      if (!field.type)
        return (
          <div className="box_c w100 mtop25">
            <div className="row w100 hcenter"></div>
            <div className="table_header w100 white hspace vcenter row">
              <p className="fontSizeSm">
                {data.form_create
                  ? data.form_create.fields[i].text
                  : "Datos generales"}
              </p>
            </div>

            <div className="padlr20">
              <div className="row w100 hcenter pad20 wrap">
                <Zoom cascade>
                  <div className="row card p-fluid row wrap hcenter w100">
                    {data.form_create != null
                      ? data.form_create.fields[i].fields.map((field) => {
                          return (
                            <Input
                              field={field}
                              modelo={modelo}
                              setModelo={setModelo}
                            />
                          );
                        })
                      : data.form.fields.map((field) => {
                          return (
                            <Input
                              field={field}
                              modelo={modelo}
                              setModelo={setModelo}
                            />
                          );
                        })}
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        );

    return (
      <div className="row w100 hcenter box_c mtop25">
        {field && (
          <table className="w100 " cellPadding={0} cellSpacing={0}>
            <tr className="bold table_header white">
              {field.fields.map((label) => (
                <td className="column ">{label.print_name}</td>
              ))}
              <td className="column ">
                <div className="row hcenter">Editar</div>
              </td>
              <td className="column ">
                <div className="row hcenter">Borrar</div>
              </td>
            </tr>
            {modelo[field.id].map((dato) => {
              return (
                <AddRow
                  field={field}
                  editO={false}
                  dato={dato}
                  datos={datos}
                  setDatos={setDatos}
                  modelo={modelo}
                  setModelo={setModelo}
                  modelO={dato}
                />
              );
            })}
            <RowA />
          </table>
        )}
      </div>
    );
  }

  useEffect(() => {

    if (search.length > 0) filter();
    else if(!checkEmpty(filterss)) setRowsSearch(rowsF)
    else setRowsSearch(rows);
  }, [search]);

  useEffect(() => {
    setMenu(app.menu);
    setEntidad(app.opciones[0]);
  }, [app]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModal) {
      setUUIDReport(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModal]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalR) {
      setUUIDForm(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalR]);

  //Mauricio
  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalCompus) {
      setUUIDFormRH(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalCompus]);



  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalHistorialCompus) {
      setUUIDHistorialCompus(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalHistorialCompus]);

  useEffect(() => {
    
    // setToggleModalHistorialCompus(false)
    const bodyClassList = document.body.classList;
    if (!toggleModalDocumento) {
      setUUIDDocumento(null);
      if(toggleModalHistorialCompus){
        setToggleModalHistorialCompus(false)
      }
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalDocumento]);
  // Mauricio
  // useEffect(() => {
  //   var params = new FormData();
  //   params.append(
  //     "uuid",
  //     uuidFormRH
  //   );

  //   Post(
  //     `${aplication}/get_by_uuid/`,
  //     (response) => {
  //       setLoader(false);
  //       pass(response);
  //       setDataAsignarCompus(response.data)
  //       // setRows(response.data.data);
  //       // setData(response.data);
  //     },
  //     (error) => {
  //       setLoader(false);
  //       pass(error);
  //     },
  //     false,
  //     "Obteniendo datos",
  //     params
  //   );
  //   setSelectedAsignado({"":"","label":dataAsignarCompus.asignado_a})
  // }, [uuidFormRH]);

  useEffect(() => {
    setAplicaicon(entidad.path);
    pass(entidad);
  }, [entidad]);

  useEffect(() => {
    setRowsSearch(rows);
  }, [rows]);

  useEffect(() => {
    setRows([]);
    setForm(false);
    setLoader(true);
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    Post(
      `${aplication}/get/`,
      (response) => {
        setLoader(false);
        pass(response);
        setRows(response.data.data);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [aplication]);

  useEffect(() => {
    pass(menu);
  }, [menu]);

  const Progress = () => { // Es un componente que no se que hace
    var steps2 = [];
    var model2 = {};
    const m = [];
    if (data)
      if (data.form_create) {
        pass(data.form_create);
        for (let i in data.form_create.fields) {
          pass(data.form_create.fields[i]);
          if (!data.form_create.fields[i].type)
            for (let k in data.form_create.fields[i].fields) {
              if (data.form_create.fields[i].fields[k].type == "select")
                model2[data.form_create.fields[i].fields[k].id] = [];
              else model2[data.form_create.fields[i].fields[k].name] = "";
            }
          else model2[data.form_create.fields[i].id] = [];
        }

        for (let i in data.form_create.fields) {
          steps2.push({
            label: data.form_create.fields[i].name,
            name: data.form_create.fields[i].name,
            content: (
              <Group
                data={data}
                i={i}
                model2={model2}
                m={m}
                field={data.form_create.fields[i]}
              />
            ),
          });
        }
      }
    return (
      <StepProgressBar
        startingStep={0}
        wrapperClass={"w100"}
        submitBtnName={`Guardar ${entidad.print}`}
        nextBtnName={"Siguiente"}
        previousBtnName={"Anterior"}
        onSubmit={() => {
          pass(m[0]);

          m[0]["public_key"] =
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L";
          m[0]["private_key"] =
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu";

          pass(m[0]);
          const requestOptions = {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(m[0]),
          };
          try {
            fetch(`${baseURL}/${data.app}/create2/`, requestOptions)
              .then((response) => {
                return new Promise((resolve) =>
                  response.json().then((json) =>
                    resolve({
                      status: response.status,
                      ok: response.ok,
                      json,
                    })
                  )
                );
              })
              .then(({ status, json, ok }) => {
                var message = "";
                if (!ok) message = json.detail.message;

                if (ok) {
                  toast.success(`${entidad.print} Agregado`, { theme: "dark" });
                  setRows(json.data);
                } else toast.error(`${status} ${message}`, { theme: "dark" });
              });
          } catch {}
        }}
        steps={steps2}
      />
    );
  };

  useEffect(() => {}, [data]);

  var cont = 1

  const descargarExcel = () => {
    window.open(baseURL + '/' + entidad.path + '/descargar-excel');
  };

  if (data != null)
    return (
      <div className="container">
        {uuidReport && (
          <ModalReporte setToggleModal={setToggleModal} uuid={uuidReport} user={user} />
        )}
        {uuidForm && (
          <ModalEdit
            setToggleModal={setToggleModalR}
            uuid={uuidForm}
            entidad={entidad}
            setRows={setRows}
          />
        )}
        {/* Mauricio */}
        
        {uuidFormRH && (
          <ModalEditDispositivosCH
            setToggleModal={setToggleModalCompus}
            uuid={uuidFormRH}
            entidad={entidad}
            setRows={setRows}
          />
        )}

        {uuidHistorialCompus && (
          <ModalHistorialDisp
            setToggleModal={setToggleModalHistorialCompus}
            uuid={uuidHistorialCompus}
            setUUIDDocumento={setUUIDDocumento}
            user={user}
            entidad={entidad}
            setToggleModalDocumento = {setToggleModalDocumento}
          />
        )}

        {uuidDocumento && (
          <ModalDocumentoCH
            setToggleModal={setToggleModalDocumento}
            uuid={uuidDocumento}
            entidad={entidad}
            user={user}
          />
        )}

        <div className="w100">
          <div className="row w100 pad10 bar hcenter vcenter  ">
            <div className="mxw1000px row vcenter hspace">
              <img
                src={Logotipo}
                alt="inicio"
                width={40}
                className={"pointier"}
              />
              <Icon
                icon={"material-symbols:logout-rounded"}
                style={{ fontSize: 40, color: "#fff" }}
                className={"pointier"}
                onClick={() => setUser(null)}
              />
            </div>
          </div>

          <div className="row pad20 wrap">

          <div className="row w100 hcenter mtop25">
            <div className="mxw1000px row vcenter hspace">
              <div className="box_c w100">
                <div
                  className="box_header w100 white bold hspace vcenter pointier"
                  {...getToggleProps({
                    style: { display: "flex" },
                    onClick: () => {
                      setExpanded((x) => !x);
                    },
                  })}
                >
                  <p className="fontSizeSm">Notificaciones</p>
                  <div className={`icon_box ${isExpanded ? "active" : ""}`}>
                    <Icon
                      className="row center dropw fontSizeLg"
                      icon={"ri:arrow-drop-down-line"}
                    />
                  </div>
                </div>

                <div className="padlr20" {...getCollapseProps()}>
                  {notificaciones.length > 0 ? (
                    notificaciones.map((notificacion) => (
                      <div className="row w100 notification hspace">
                        <p>
                          {notificacion.created.substring(0, 10)} -{" "}
                          {notificacion.message}{" "}
                          
                          {notificacion.type == "documento_firmado" && notificacion.message == "Se te ha asignado un celular" && (
                            <span
                              className="bold pointier"
                              style={{
                                color: "#1461FF",
                                textDecoration: "underline",
                              }}
                              onClick={() => {

                                setApp(menus[3])
                                setEntidad(app.opciones[1]);
                                setToggleModalDocumento(true);
                                setUUIDDocumento(notificacion.uuid_redirect);
                                
                              }}
                            >
                              Revisalo ahora
                            </span>
                          )}

                          {notificacion.type == "documento_firmado" && notificacion.message == "Se te ha asignado una computadora" && (
                            <span
                              className="bold pointier"
                              style={{
                                color: "#1461FF",
                                textDecoration: "underline",
                              }}
                              onClick={() => {

                                setApp(menus[3])
                                setEntidad(app.opciones[0]);
                                setToggleModalDocumento(true);
                                setUUIDDocumento(notificacion.uuid_redirect);
                                
                              }}
                            >
                              Revisalo ahora
                            </span>
                          )}
                        </p>
                        <span
                          className="row vcenter pointier"
                          onClick={() => {
                            for (let i in notificaciones) {
                              if (notificacion == notificaciones[i]) {
                                let cpArray = notificaciones.filter(
                                  (notificacionO) =>
                                    notificacionO != notificaciones[i]
                                );
                                setNotificaciones(cpArray);
                              }
                            }
                            var params = new FormData();
                            params.append(
                              "public_key",
                              "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                            );
                            params.append(
                              "private_key",
                              "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                            );
                            params.append("uuid", notificacion.uuid);
                            Post(
                              `notification/read/`,
                              (response) => {},
                              (error) => {},
                              false,
                              "Obteniendo datos",
                              params
                            );
                          }}
                        >
                          Archivar
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="row w100 notification hspace">
                      <p>No tienes notificaciones</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>



            <div className="row w100 hcenter">
              <div className="mxw1000px row vcenter hspace mtop25">
                <h2 className="fontSizeLg lighter ">
                  Bienvenido{" "}
                  <span className="bold">
                    {user.nombre} {user.apellido_p}
                  </span>
                </h2>
              </div>
            </div>

            <div className="row w100 hcenter mtop25">
              <Zoom cascade>
                <div className="mxw800px row vcenter hspace mtop25">
                  <div
                    className={`menu_option_hover pointier w100 row wrap`}
                    onClick={() => {
                      setApp(menus[0]);
                    }}
                  >
                    <div className="row hcenter w100">
                      <div
                        className={`menu_option pointier ${
                          menu == menus[0].menu ? "active" : ""
                        }`}
                      >
                        <ReactSVG src={Empleadoss} />
                      </div>
                    </div>
                    <div
                      className="row hcenter mtop15 bold w100 t_center"
                      style={{ height: 30 }}
                    >
                      Gestión de Empleados y Aprobadores
                    </div>
                  </div>

                  <div
                    className={`menu_option_hover pointier w100 row wrap`}
                    onClick={() => {
                      setApp(menus[1]);
                    }}
                  >
                    <div className="row hcenter w100">
                      <div
                        className={`menu_option pointier ${
                          menu == menus[1].menu ? "active" : ""
                        }`}
                      >
                        <ReactSVG src={Actividades} />
                      </div>
                    </div>
                    <div
                      className="row hcenter mtop15 bold w100 t_center"
                      style={{ height: 30 }}
                    >
                      Hojas de Actividades
                    </div>
                  </div>

                  <div
                    className={`menu_option_hover pointier w100 row wrap`}
                    onClick={() => {
                      setApp(menus[2]);
                    }}
                  >
                    <div className="row hcenter w100">
                      <div
                        className={`menu_option pointier ${
                          menu == menus[2].menu ? "active" : ""
                        }`}
                      >
                        <ReactSVG src={Asignaciones} />
                      </div>
                    </div>
                    <div
                      className="row hcenter mtop15 bold w100 t_center"
                      style={{ height: 30 }}
                    >
                      Gestión de Asignaciones
                    </div>
                  </div>

                  <div
                    className={`menu_option_hover pointier w100 row wrap`}
                    onClick={() => {
                      setApp(menus[3]);
                    }}
                  >
                    <div className="row hcenter w100">
                      <div
                        className={`menu_option pointier ${
                          menu == menus[3].menu ? "active" : ""
                        }`}
                      >
                        <ReactSVG src={compu} />
                      </div>
                    </div>
                    <div
                      className="row hcenter mtop15 bold w100 t_center"
                      style={{ height: 30 }}
                    >
                      Asignacion de dispositivos
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>

            <div className="row w100 hcenter mtop25">
              <div className="mxw1000px row vcenter">
                <h3 className="fontSizeMd ">{app.name}</h3>
              </div>
            </div>

            <div className="row w100 hcenter mtop25">
              <div className="mxw1000px row vcenter">
                <p className="mxw800px">{app.description}</p>
              </div>
            </div>

            <div className="row w100 hcenter">
              <div className="mxw1000px row vcenter mtop25 hspace">
                <div className="row vcenter">
                  {data.filter ? (
                    <Filter
                      setFilters={setFilters}
                      rows={rows}
                      filters={data.filter}
                      rowsSearch={rowsSearch}
                      setRowsSearch={setRowsSearch}
                      filterss={filterss}
                      search={search}
                      setRowsF={setRowsF}
                    />
                  ) : (
                    ""
                  )}

                  {app.opciones.map((opcion) => (
                    <div
                      className={`btn_2 row vcenter pointier relative ${
                        aplication == opcion.path && !form ? "active" : ""
                      }`}
                      onClick={() => {
                        setEntidad(opcion);
                        setForm(false);
                      }}
                    >
                      {opcion.name}
                    </div>
                  ))}
                </div>
                {entidad.add && (
                  <div
                    className={`btn_2 pointier row vcenter ${
                      form ? "active" : ""
                    }`}
                    onClick={() => {
                      setForm(!form);
                    }}
                  >
                    Agregar {entidad.print}
                  </div>
                )}
                {/* Mauricio */}
                {entidad.path === "compus-rh" && (
                  <div
                    className={`btn_2 pointier row vcenter`}
                    onClick={() => {
                      descargarExcel();
                    }}
                  >
                    Descargar lista computadoras
                  </div>
                )}
                {entidad.path === "celulares-rh" && (
                  <div
                    className={`btn_2 pointier row vcenter`}
                    onClick={() => {
                      descargarExcel();
                    }}
                  >
                    Descargar lista celulares
                  </div>
                )}
              </div>
            </div>
            <div className="row w100 hcenter">
              <div className="mxw1000px row vcenter mtop25">
                <Actions setSearch={setSearch} search={search} />
              </div>
            </div>

            <div className="row w100 hcenter mtop25">
              <div className="row mxw1000px relative ">
                {entidad.add && data.form_create && (
                  <Fade right collapse when={form}>
                    {form && (
                      <div className="w100 absolute">
                        <div className="row w100 wrap">
                          <div className="row w100 hcenter">
                            <Progress />
                          </div>
                        </div>
                      </div>
                    )}
                  </Fade>
                )}

                <Fade left when={!form}>
                  {loader ? (
                    <div className="box_c w100 absolute">
                      <div className="table_header pad15 row fontSizeSmr">
                        <span className="white w100 row">
                          <Icon icon={"ph:dots-three-outline-fill"} />
                        </span>
                        <span className="white w100 row">
                          <Icon icon={"ph:dots-three-outline-fill"} />
                        </span>
                        <span className="white w100 row">
                          <Icon icon={"ph:dots-three-outline-fill"} />
                        </span>
                        <span className="white w100 row">
                          <Icon icon={"ph:dots-three-outline-fill"} />
                        </span>
                      </div>
                      <div className="row w100 hcenter pad15 vcenter">
                        <ThreeCircles
                          height="43"
                          width="43"
                          color="#0061FF"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="three-circles-rotating"
                          outerCircleColor=""
                          innerCircleColor=""
                          middleCircleColor=""
                        />
                      </div>
                    </div>
                  ) : (rows != null) & (rows.length > 0) ? (
                    !form && (
                      <table
                        className="box_c w100 absolute"
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <thead className="table_header pad15">
                          <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                          {entidad.path === "compus-rh" | entidad.path === "celulares-rh" ? (
                          <td className="column">No</td>
                          ):""}
                            {Object.keys(rows[0]).map(
                              (key) =>
                              key != "Tipo de SIM" &&
                              key != "Sistema Operativo" &&
                              key != "Ultimo mantenimiento" &&
                                key != "Siguiente mantenimiento" &&
                                key != "Color" &&
                                key != "Tipo de bateria" &&
                                key != "Asignado por" &&
                                key != "Vida util hasta" &&
                                key != "Número de serie" &&
                                key != "Batería (Interna/ext)" &&
                                key != "Observaciones generales" &&
                                key != "Año de lanzamiento" &&
                                key != "Vida útil hasta" &&
                                key != "Es doble SIM" &&
                                key != "Tipo de cargador" &&
                                key != "Porcentaje de batería" &&
                                  key != "uuid" &&
                                  key != "options" &&
                                  key != "ready" && (
                                  <td className="column" key={key}>
                                    {key}
                                  </td>
                                )
                            )}
                            <td className="column"></td>
                          </tr>
                        </thead>
                        <Zoom cascade>
                          <tbody>
                            {rowsSearch.map((row) => (
                              <tr className="row_table">
                                {entidad.path === "compus-rh" | entidad.path === "celulares-rh" ? (
                                <td className="column">{cont++}</td>
                                ):""}
                                {Object.keys(row).map(
                                  (key) =>
                                  key != "Tipo de SIM" &&
                                  key != "Sistema Operativo" &&
                                  key != "Ultimo mantenimiento" &&
                                key != "Siguiente mantenimiento" &&
                                key != "Color" &&
                                key != "Tipo de bateria" &&
                                key != "Asignado por" &&
                                key != "Número de serie" &&
                                key != "Vida util hasta" &&
                                key != "Batería (Interna/ext)" &&
                                key != "Observaciones generales" &&
                                key != "Año de lanzamiento" &&
                                key != "Vida útil hasta" &&
                                key != "Es doble SIM" &&
                                key != "Tipo de cargador" &&
                                key != "Porcentaje de batería" &&
                                  key != "uuid" &&
                                  key != "options" &&
                                  key != "ready" &&
                                    (key == "Estatus de reporte" ? (
                                      <td className={`column`}>
                                        <span
                                          className={`${row[
                                            key
                                          ].toLowerCase()}`}
                                          style={{
                                            maxWidth: 100,
                                            margin: 0,
                                            margin: "auto",
                                          }}
                                        >
                                          {row[key]}
                                        </span>{" "}
                                      </td>
                                    ) : key == "Estado" ? (
                                      <td className={`column row`}>
                                        <span
                                          className={`${row[
                                            key
                                          ].toLowerCase()}`}
                                        >
                                          {row[key]}
                                        </span>{" "}
                                      </td>
                                    ) : key == "Contrato" ? (
                                      <td className={`column`}>
                                        {row[key] == "Sin contrato" ? (
                                          row[key]
                                        ) : (
                                          <a
                                            href={`https://portal.eks-solutions.com:8000${row[key]}`}
                                            target="_blank"
                                            style={{ textDecoration: "none" }}
                                          >
                                            Abrir
                                          </a>
                                        )}
                                      </td>
                                    ) : (
                                      <td className={`column`}>{row[key]} </td>
                                    ))
                                )}
                                <td className="column">
                                  <div className="row">
                                    {entidad.path == "hoja_actividades" && (
                                      <Icon
                                        icon={"ic:baseline-remove-red-eye"}
                                        className="fontSizeLg pointier hover_green"
                                        onClick={() => {
                                          setUUIDReport(row["uuid"]);
                                          setToggleModal(true);
                                        }}
                                      />
                                    )}
                                    {entidad.path != "hoja_actividades" && 
                                    entidad.path != "compus-rh" && entidad.path != "celulares-rh" &&(
                                      <Icon
                                        icon={
                                          "material-symbols:edit-square-outline-rounded"
                                        }
                                        className="fontSizeLg pointier hover_green"
                                        onClick={() => {
                                          setToggleModalR(true);
                                          setUUIDForm(row["uuid"]);
                                        }}
                                      />
                                    )}
                                    {entidad.path === "compus-rh" | entidad.path === "celulares-rh" ? (
                                      <Icon
                                        icon={
                                          "material-symbols:edit-square-outline-rounded"
                                        }
                                        className="fontSizeLg pointier hover_green"
                                        onClick={() => {
                                          setToggleModalCompus(true);
                                          setUUIDFormRH(row["uuid"]);
                                        }}
                                      />
                                      
                                    ):""}
                                  </div>
                                  {entidad.path === "compus-rh" | entidad.path === "celulares-rh" ? (
                                  <div className="row mtop_7px">
                                      
                                      <Icon
                                          icon={
                                            "material-symbols:history-rounded"
                                          }
                                          className="fontSizeLg pointier hover_green"
                                          onClick={() => {
                                            setToggleModalHistorialCompus(true);
                                            setUUIDHistorialCompus(row["uuid"]);
                                          }}
                                        />
                          
                                  </div>
                                  ):""}
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </Zoom>
                      </table>
                    )
                  ) : (
                    <div className="table_header pad15 row vcenter hcenter white bold fontSizeSm w100">
                      Sin registros
                    </div>
                  )}
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
